import { useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { editPackage } from '../../state/api/package';

function EditPackage({ packages, setDep }) {
    const [errorMsg, setErrorMsg] = useState(null);
    const toast = useToast();
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const onSubmit = async (formData) => {
        try {
            const { data } = await editPackage(packages.id, { ...formData, validity: Number(formData.validity) });
            console.log(data);
            setDep(Math.random());
            setErrorMsg(data?.message);
            reset();
            toast({
                title: 'Package edited successfully',
                // description: "We've created your account for you.",
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
            window.location.reload(false);
        } catch (error) {

        }
    };

    useEffect(() => {
        return () => {

        }
    }, []);
    return (
        <div className="container">
            <form onSubmit={handleSubmit(onSubmit)} action="">
                <div className="row">
                    <div className="col-md-4">

                        <div class="mb-3">
                            <label for="name" class="form-label text-light">Package Name</label>
                            <input
                                defaultValue={packages.name}
                                {...register("name", { required: false })}
                                type="name" class="form-control input-primary" id="name" aria-describedby="emailHelp" />
                        </div>
                        <div class="mb-3">
                            <label for="Duration" class="form-label text-light">Duration</label>
                            <select
                                {...register("validity", { required: false })}
                                className="form-select input-primary" id="Duration" aria-label="Default select example">
                                <option value={packages.validity} selected>{packages.validity} Days</option>
                                <option value="7" >7 Days</option>
                                <option value="15">15 Days</option>
                                <option value="30">30 Days</option>
                            </select>
                        </div>
                        <div class="mb-3 ">
                            <label for="Discount" class="form-label text-light">Discount</label>
                            <div className="special-input">
                                <input
                                    defaultValue={packages.discount}
                                    {...register("discount", { required: false })}
                                    type="number" class="form-control  input-primary" id="Discount" />
                                <span>%</span>
                            </div>
                        </div>

                    </div>
                    {/* 2nd column */}
                    <div className="col-md-4">

                        <div class="mb-3 ">
                            <label for="Price" class="form-label text-light">Price</label>
                            <div className="special-input">
                                <input
                                    defaultValue={packages.amount}
                                    {...register("amount", { required: false })}
                                    type="number" class="form-control  input-primary" id="Price" />
                                <span>BDT</span>
                            </div>
                        </div>
                        <div class="mb-3 ">
                            <label for="address" class="form-label text-light">Profit share</label>
                            <div className="special-input">
                                <input
                                    defaultValue={packages.share}
                                    {...register("share", { required: false })}
                                    type="number" class="form-control  input-primary" id="address" />
                                <span>%</span>
                            </div>
                        </div>


                    </div>

                    <div class="mb-3 col-md-8">
                        <label for="description" class="form-label text-light">Description</label>
                        <textarea
                            defaultValue={packages.description}
                            {...register("description", { required: false })}
                            class="form-control input-primary" id="description" rows="3"></textarea>
                    </div>
                </div>
                <div className="pt-3 pb-3 text-center">
                    <div className="text-center fw-bold text-danger pb-2">
                        {
                            errorMsg && <p>{errorMsg}</p>
                        }
                    </div>
                    <button className="btn btn-secondary">
                        Submit
                    </button>
                </div>
            </form>
        </div >
    )
}

export default EditPackage
