import React from 'react'
import Notifications from "react-notifications-menu"
import Sidebar from './Sidebar'
import {
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
} from "@chakra-ui/react";
import {
    ChevronDownIcon
} from "@chakra-ui/icons";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setFalse } from '../state/reducer/login';

function Navbar() {

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const data = [
        {
            image: 'https://images.unsplash.com/photo-1639165637996-d6e79aff8823?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxN3x8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
            message: 'Lorem ipsum dolor sit amet.',
            detailPage: '/events',
            receivedTime: '12h ago'
        },
        {
            image: 'https://images.unsplash.com/photo-1639165637996-d6e79aff8823?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxN3x8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
            message: 'Lorem ipsum dolor sit amet2.',
            detailPage: '/events',
            receivedTime: '12h ago'
        },
        {
            image: 'https://images.unsplash.com/photo-1639165637996-d6e79aff8823?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxlZGl0b3JpYWwtZmVlZHwxN3x8fGVufDB8fHx8&auto=format&fit=crop&w=500&q=60',
            message: 'Lorem ipsum dolor sit amet4.',
            detailPage: '/events',
            receivedTime: '12h ago'
        },
    ];

    const LogoutAdmin = () => {
        localStorage.clear();
        dispatch(setFalse());
        navigate("/");
    }


    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container-fluid">
                <a className="navbar-brand" href="#">
                    <Sidebar />
                </a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                        {/* <li className="nav-item me-2">
                            <Notifications
                                data={data}
                                header={{
                                    title: "Support",
                                    option: { onClick: () => console.log("Clicked") }
                                }}
                                markAsRead={(data) => {
                                    console.log(data);
                                }}
                                notificationCard={SupportNotiFicationCard}
                            />
                        </li>
                        <li className="nav-item">
                            <Notifications
                                data={data}
                                header={{
                                    title: "Notifications",
                                    option: { onClick: () => console.log("Clicked") }
                                }}
                                markAsRead={(data) => {
                                    console.log(data);
                                }}
                                notificationCard={SupportNotiFicationCard}
                            />
                        </li> */}
                        <li className="nav-item">
                            <Menu>
                                <MenuButton as={Button} mr="20px" rightIcon={<ChevronDownIcon />}>
                                    {localStorage.getItem("name")}
                                </MenuButton>
                                <MenuList>
                                    <MenuItem onClick={LogoutAdmin}>Log Out</MenuItem>
                                </MenuList>
                            </Menu>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

function SupportNotiFicationCard(props) {
    const { image, message, receivedTime } = props.data;
    return (
        <div className="card mt-2">
            <a href="/events" className="card-link">
                <div className="content"><div className="image">
                    <img src={image} alt="Person " />
                </div>
                    <div className="message">
                        <div className="text">{message}</div>
                        <div className="time">
                            {receivedTime}
                        </div>
                    </div>
                </div>
            </a>
            <div className="options">
            </div>
        </div>
    )
}

export default Navbar
