import React from 'react';
import '../../styles/DashboardCard.css';

function DashboardCard({ children, size, title, mainValue, otherInfo }) {
    return (
        <div className={`dashboardCardWrapper ${ size === "sm" ? "sm" : (size === "md" ? "md" : (size === "xl" ? "xl" : "sm")) }`}>
            <h1>{title}</h1>
            <h4 className='text-center'>{mainValue}</h4>
            <div className='d-flex ps-4 pb-4'>
                {otherInfo?.map((item) => {
                    return (
                        <div className='text-center me-3'>
                            <h2 className='pb-1'>{item.title}</h2>
                            <h3 className='fw-bold'>{item.value}</h3>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

export default DashboardCard
