import React, { useEffect, useState } from 'react'
import NursePackageListTable from '../../components/NursePackage/NursePackageListTable'
import { allNursePackage } from '../../state/api/nurse';

function NursePackageList() {

    const [nursePackages, setNursePackages] = useState([]);
    const [dep, setDep] = useState(null);

    // code of pagination start
    const [pageNumber, setPageNumber] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);
    // code of pagination end

    const handleGetAndSetNursePackages = async (pageNumber) => {
        try {
            const { data } = await allNursePackage(pageNumber);
            setNursePackages(data)
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleGetAndSetNursePackages();
    }, [dep]);
    return (
        <div className="container pt-5">
            <h1 className="fw-bold fs-1">Nurse</h1>
            <NursePackageListTable
                nursePackages={nursePackages}
                setDep={setDep}
            />
        </div>
    )
}

export default NursePackageList
