import { Button } from '@chakra-ui/button'
import { useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { deleteDoctor, editDoctor } from '../../state/api/doctor'
import ModalComponent from '../ModalComponent'
import SearchBer from '../SearchBer'
import AddDoctor from './AddDoctor'
import DoctorDetails from './DoctorDetails'
import EditDoctor from './EditDoctor'

function DoctorListTable({ doctors, setDep, setData, pageNumber, setNumberOfPages }) {
    const toast = useToast();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center pb-3">
                <h1 className="fw-bold fs-3 pt-3 pb-3">
                    Doctor List
                </h1>

                {/* search bar */}
                <SearchBer
                    setData={setData}
                    type={"doctors"}
                    pageNumber={pageNumber}
                    setNumberOfPages={setNumberOfPages}
                />

                <ModalComponent
                    btnClassName={"add-people"}
                    btnIcon={"fas fa-plus-circle"}
                    btnText={"Add Doctor"}
                    title={"Add Doctor"}
                >
                    <AddDoctor
                        setDep={setDep} />
                </ModalComponent>
            </div>

            {/* list table */}
            <table className="table main-table">
                <thead>
                    <tr>
                        <th scope="col">
                            Name and Details
                        </th>
                        <th scope="col">
                            Department
                        </th>
                        <th scope="col">
                            Specialty
                        </th>
                        <th scope="col">
                            Contact
                        </th>
                        <th scope="col">
                            Status
                        </th>
                        <th scope="col">
                            Action
                        </th>
                        <th scope="col">

                        </th>
                    </tr>
                </thead>
                <div className="pt-3">

                </div>
                <tbody >
                    {
                        doctors?.map((doctor, index) => {
                            return (
                                <tr >

                                    <td className="userDetailsInTable">
                                        <div className="d-flex align-items-center">
                                            <img src={doctor?.photo}
                                                className="img-fluid rounded-circle userImage"
                                                alt={doctor.name} />
                                            <div className="ms-3">
                                                <h1 className="fw-600 fs-4">{doctor.name}</h1>
                                                <p className="text-light">{doctor.email}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{doctor.doctor_department_id}</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{doctor.speciality}</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{doctor.phone}</h1>
                                    </td>
                                    <td className="activeInactiveCheckBox">
                                        <div className="form-check form-switch">
                                            {
                                                doctor.is_active === "1"
                                                    ? <input onChange={async () => {
                                                        await editDoctor(doctor.id, { is_active: false });
                                                        setDep(Math.random());
                                                    }} defaultChecked className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                    : <input onChange={async () => {
                                                        await editDoctor(doctor.id, { is_active: true });
                                                        setDep(Math.random());
                                                    }} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <ModalComponent
                                            btnClassName={"add-people"}
                                            btnIcon={"far fa-eye"}
                                            btnText={"View"}
                                            title={"Doctor Details"}
                                        >
                                            {/* Doctor details Component */}
                                            <DoctorDetails
                                                doctor={doctor}
                                            />
                                        </ModalComponent>
                                    </td>
                                    <td className="text-center">
                                        <ModalComponent
                                            btnClassName={"btn-editUserProfile"}
                                            btnIcon={"fas fa-edit"}
                                            btnText={"Edit"}
                                            title={"Edit Doctor"}
                                        >
                                            {/* Edit Doctor component */}
                                            <EditDoctor
                                                setDep={setDep}
                                                doctor={doctor}
                                            />
                                        </ModalComponent>
                                        <br />
                                        <Button
                                            onClick={() => {
                                                deleteDoctor(doctor.id);
                                                setDep(Math.random());
                                                toast({
                                                    title: "Doctor Deleted",
                                                    description: "Doctor Deleted Successfully",
                                                    status: "success",
                                                    duration: 9000,
                                                    isClosable: true,
                                                    position: "bottom-right",
                                                    isCloseButton: true,
                                                    isIcon: true,
                                                    icon: "check-circle",
                                                    className: "toast-success"
                                                });
                                            }}
                                            mt="1"
                                            padding="0px 35px"
                                            fontSize="12"
                                            height="25px"
                                            borderRadius="30px"
                                            color="#000">
                                            <i class="far fa-trash-alt me-2"></i>
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div >
    )
}

export default DoctorListTable
