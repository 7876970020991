import React, { useEffect, useState } from 'react'
import Pagination from '../../components/Pagination';
import TherapistListTable from '../../components/Therapist/TherapistListTable'
import { allTherapists } from '../../state/api/therapists';

function TherapistList() {
    const [therapists, setTherapists] = useState([]);
    const [dep, setDep] = useState(null);

    // code of pagination start
    const [pageNumber, setPageNumber] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);
    // code of pagination end

    const handleGetAndSetTherapists = async (pageNumber) => {
        try {
            const { data } = await allTherapists(pageNumber);
            setTherapists(data);
            setNumberOfPages(Math.ceil(data.total / data.per_page));
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleGetAndSetTherapists(pageNumber);
    }, [dep, pageNumber]);
    return (
        <div className="container pt-5">
            <h1 className="fw-bold fs-1">Therapist</h1>
            <TherapistListTable
                therapists={therapists?.data}
                setDep={setDep}
                setData={setTherapists}
                pageNumber={pageNumber}
                setNumberOfPages={setNumberOfPages}
            />
            <div className="text-center pb-3">
                {numberOfPages > 1 && (
                    <Pagination
                        setPageNumber={setPageNumber}
                        numberOfPages={numberOfPages}
                        pageNumber={pageNumber}
                    />
                )}
            </div>
        </div>
    )
}

export default TherapistList
