import { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import './App.css';
import Navbar from "./components/Navbar";
import NursePackageList from "./pages/Nurse/NursePackageList";
import Login from "./pages/Login";
import NurseList from "./pages/Nurse/NurseList";
import Test from "./pages/test";
import DoctorList from "./pages/Doctor/DoctorList";
import PatientGuideList from "./pages/PatientGuide/PatientGuideList";
import PatientGuidePackageList from "./pages/PatientGuide/PatientGuidePackageList";
import TherapistList from "./pages/Therapist/TherapistList";
import TherapistLocationList from "./pages/Therapist/TherapistLocationList";
import ForeignMedicalAssList from "./pages/ForeignMedicalAss/ForeignMedicalAssList";
import PackageList from "./pages/Package/PackageList";
import HomeSampleCollectionList from "./pages/HomeSampleCollection/HomeSampleCollectionList";
import HomeSampleCategoryList from "./pages/HomeSampleCollection/HomeSampleCategoryList";
import CareGiverList from "./pages/CareGiver/CareGiverList";
import CareGiverServiceList from "./pages/CareGiver/CareGiverServiceList";
import Dashboard from "./pages/Dashboard/Dashboard";
import UserList from "./pages/User/UserList";
import Report from "./pages/Report/Report";
import ServiceRequest from "./pages/ServiceRequest/ServiceRequest";
import Settings from "./pages/Settings/Settings";
import { useDispatch, useSelector } from "react-redux";
import { setFalse, setTrue } from "./state/reducer/login";
import Department from "./pages/Doctor/Depertment";
import Contact from "./pages/Contact";
import Support from "./pages/Support";
import Blogs from "./pages/Blogs/Blogs";

function App() {
  const [authenticated, setAuthenticated] = useState(null);
  const dispatch = useDispatch()
  const token = localStorage.getItem("token");
  const status = useSelector((state) => state.login.status);
  const [type, setType] = useState();

  
  if (token) {
    dispatch(setTrue());
  } else {
    dispatch(setFalse());
  }

  useEffect(() => {
    setType(localStorage.getItem("type"))
    return () => {

    };
  }, [status]);
  return (
    <>
      {(status && type === "super") ? <AuthRoutesForSuperAdmin /> : (status && type === "sub" ? <AuthRoutesForSubAdmin /> : <NoAuthRoutes />)}
    </>
  );
}



function AuthRoutesForSuperAdmin() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/test" element={<Test />} />
        <Route exact path="/" element={<Dashboard />} />
        <Route exact path="/dashboard" element={<Dashboard />} />
        <Route path="/nurses" element={<NurseList />} />
        <Route path="/nurse/packages" element={<NursePackageList />} />
        <Route path="/doctors" element={<DoctorList />} />
        <Route path="/doctors/department" element={<Department />} />
        <Route path="/patient-guides" element={<PatientGuideList />} />
        <Route path="/patient-guide/packages" element={<PatientGuidePackageList />} />
        <Route path="/therapists" element={<TherapistList />} />
        <Route path="/therapist/location" element={<TherapistLocationList />} />
        <Route path="/foreign-medical-assistance" element={<ForeignMedicalAssList />} />
        <Route path="/home-sample-collection" element={<HomeSampleCollectionList />} />
        <Route path="/home-sample-collection/category" element={<HomeSampleCategoryList />} />
        <Route path="/package" element={<PackageList />} />
        <Route path="/care-giver" element={<CareGiverList />} />
        <Route path="/care-giver/service" element={<CareGiverServiceList />} />
        <Route path="/user" element={<UserList />} />
        <Route path="/report" element={<Report />} />
        <Route path="/service-request/:typeOf" element={<ServiceRequest />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/support" element={<Support />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/blogs" element={<Blogs />} />
      </Routes>
    </>
  )
}

function AuthRoutesForSubAdmin() {
  return (
    <>
      <Navbar />
      <Routes>
        <Route path="/test" element={<Test />} />
        <Route path="/nurses" element={<NurseList />} />
        <Route path="/nurse/packages" element={<NursePackageList />} />
        <Route path="/doctors" element={<DoctorList />} />
        <Route path="/doctors/department" element={<Department />} />
        <Route path="/patient-guides" element={<PatientGuideList />} />
        <Route path="/patient-guide/packages" element={<PatientGuidePackageList />} />
        <Route path="/therapists" element={<TherapistList />} />
        <Route path="/therapist/location" element={<TherapistLocationList />} />
        <Route path="/foreign-medical-assistance" element={<ForeignMedicalAssList />} />
        <Route path="/home-sample-collection" element={<HomeSampleCollectionList />} />
        <Route path="/home-sample-collection/category" element={<HomeSampleCategoryList />} />
        <Route path="/package" element={<PackageList />} />
        <Route path="/care-giver" element={<CareGiverList />} />
        <Route path="/care-giver/service" element={<CareGiverServiceList />} />
        <Route path="/user" element={<UserList />} />
        <Route path="/service-request/:typeOf" element={<ServiceRequest />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/support" element={<Support />} />
        <Route path="/blogs" element={<Blogs />} />
      </Routes>
    </>
  )
}

function NoAuthRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
      </Routes>
    </>
  )
}

export default App;
