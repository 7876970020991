import axios from "axios";

export const allNurse = async (pageNumber) =>
    await axios.get(`/admin/nurses?page=${ pageNumber }`);

export const addNurse = async (data) =>
    await axios.post(`/admin/nurses`, data);

export const editNurse = async (id, data) =>
    await axios.put(`/admin/nurses/${ id }`, data);

export const deleteNurse = async (id) =>
    await axios.delete(`/admin/nurses/${ id }`);

export const allNursePackage = async (pageNumber) =>
    await axios.get(`/admin/nurse_packages?page=${ pageNumber }`);

export const addNursePackage = async (data) =>
    await axios.post(`/admin/nurse_packages`, data);

export const editNursePackage = async (id, data) =>
    await axios.put(`/admin/nurse_packages/${ id }`, data);

export const deleteNursePackage = async (id) =>
    await axios.delete(`/admin/nurse_packages/${ id }`);

