import { useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { addForeignMedicalLocation, allForeignMedicalLocations, deleteForeignMedicalLocation } from '../../state/api/foreign_medical';

function AddCountry() {

    const [ForeignMedicalLocations, setForeignMedicalLocation] = useState([]);
    const [dep, setDep] = useState(null);
    const toast = useToast();

    const handleGetAndSetForeignMedicalLocation = async () => {
        try {
            const { data } = await allForeignMedicalLocations();
            setForeignMedicalLocation(data)
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }

    // add country
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const handleAddCountry = async (body) => {
        try {
            const { data } = await addForeignMedicalLocation(body);
            console.log(data);
            setDep(data);
            reset();
            toast({
                title: 'Country added successfully',
                // description: "We've created your account for you.",
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        } catch (error) {
            console.log(error)
        }
    };

    const handleDeleteCountry = async (body) => {
        console.log(body)
        try {
            const { data } = await deleteForeignMedicalLocation(body.id);
            console.log(data);
            setDep(data);
            reset();
            toast({
                title: 'Country deleted successfully',
                // description: "We've created your account for you.",
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        handleGetAndSetForeignMedicalLocation();
    }, [dep]);

    return (
        <div className='container-fluid pt-2 pb-5'>
            <div className="row">
                <div className="col-md-6">
                    <form onSubmit={handleSubmit(handleAddCountry)} class="">
                        <h1 className="fs-6 fw-bold">Add Country</h1>
                        <div className="md-3">
                            <label for="addCountry" class="form-label text-light">Country Name</label>
                            <div className="d-flex">
                                <input
                                    {...register("country", { required: false })}
                                    class="form-control input-primary me-2"
                                    type="text"
                                    id='addCountry'
                                    aria-label="addCountry" />
                                <button
                                    class="btn btn-plus"
                                    type="submit"><i class="fas fa-plus-circle"></i></button>
                            </div>
                        </div>
                    </form>
                </div>
                <div className="col-md-6">
                    <form onSubmit={handleSubmit(handleDeleteCountry)} class="">
                        <h1 className="fs-6 fw-bold">Delete Country</h1>
                        <div className="md-3">
                            <label for="addCountry" class="form-label text-light">Country Name</label>
                            <div className="d-flex">
                                <select
                                    {...register("id", { required: false })}
                                    class="form-select me-2 input-primary" id="Gender" aria-label="Default select example">
                                    {
                                        ForeignMedicalLocations?.map((location, index) => {
                                            return (
                                                <option value={location.id}>{location.country}</option>
                                            )
                                        })
                                    }
                                </select>
                                <button
                                    class="btn btn-plus"
                                    type="submit"><i class="fas fa-minus-circle"></i></button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            <div className="pt-3">
                <h1 className="fs-6 fw-bold">List of available Country</h1>
                {/* Data table */}
                <div className="col-md-4 mt-3">
                    <table class="table table-bordered">
                        {/* <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead> */}
                        <tbody>
                            {
                                ForeignMedicalLocations?.map((ForeignMedicalLocation, index) => {
                                    return (
                                        <tr>
                                            <th scope="row">{index + 1}</th>
                                            <td>Country</td>
                                            <td>{ForeignMedicalLocation.country}</td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default AddCountry
