import { useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { addNurse } from '../../state/api/nurse';
import { uploadImage } from '../../state/api/upImage';

function AddNurse({ setDep }) {
    // this state using at label of image input
    const [selectedImg, setSelectedImg] = useState(null);
    const days = [
        {
            id: 1,
            name: "Saturday",
            available: false
        },
        {
            id: 2,
            name: "Sunday",
            available: true
        },
        {
            id: 3,
            name: "Monday",
            available: false
        },
        {
            id: 4,
            name: "Tuesday",
            available: false
        },
        {
            id: 5,
            name: "Wednesday",
            available: false
        },
        {
            id: 6,
            name: "Thursday",
            available: false
        },
        {
            id: 7,
            name: "Friday",
            available: false
        }
    ];

    const [imgURL, setImgURL] = useState("");
    const [errorMsg, setErrorMsg] = useState(null);
    const toast = useToast();

    const handleImageUpload = async (file) => {
        console.log(file);
        try {
            const imgData = new FormData();
            imgData.append("photo", file);

            const { data } = await uploadImage(imgData);
            console.log(data);
            setImgURL(data.url);
            toast({
                title: 'Image Upload Successfully',
                // description: "We've created your account for you.",
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        } catch (error) {

        }
    }

    const [availableDays, setAvailableDays] = useState([]);
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const onSubmit = async (formData) => {
        formData.photo = imgURL;
        try {
            const { data } = await addNurse({ ...formData, availabilities: availableDays.toString() });
            console.log(data);
            setDep(data);
            setErrorMsg(data?.message);
            reset();
            toast({
                title: 'Nurse added successfully',
                // description: "We've created your account for you.",
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
            setSelectedImg(null);
        } catch (error) {
            setErrorMsg(JSON.stringify(error?.response?.data?.errors));
        }
    };

    useEffect(() => {
        return () => {

        }
    }, []);

    return (
        <div className="container">
            <form onSubmit={handleSubmit(onSubmit)} action="">
                <div className="row">
                    <div className="col-md-8">
                        <div className="d-flex">
                            {/* first column */}
                            <div className="w-50 ">
                                <div class="mb-3">
                                    <label for="name" class="form-label text-light">Name</label>
                                    <input
                                        {...register("name", { required: true })}
                                        type="name" class="form-control input-primary" id="name" aria-describedby="emailHelp" />
                                </div>
                                <div class="mb-3">
                                    <label for="dateOfBirth" class="form-label text-light">Date of Birth</label>
                                    <input
                                        {...register("dob", { required: true })}
                                        type="date" class="form-control  input-primary" id="dateOfBirth" />
                                </div>
                                <div class="mb-3">
                                    <label for="Email" class="form-label text-light">Email</label>
                                    <input
                                        {...register("email", { required: true })}
                                        type="email" class="form-control  input-primary" id="Email" />
                                </div>
                                <div class="mb-3">
                                    <label for="Phone" class="form-label text-light">Phone</label>
                                    <input
                                        {...register("phone", { required: true })}
                                        type="phoneNumber" class="form-control input-primary" id="Phone" />
                                </div>
                            </div>
                            {/* 2d column */}
                            <div className="w-50 ps-3">
                                <div class="mb-3">
                                    <label for="designation" class="form-label text-light">Designation</label>
                                    <input
                                        {...register("designation", { required: true })}
                                        type="name" class="form-control input-primary" id="designation" aria-describedby="emailHelp" />
                                </div>
                                <div class="mb-3">
                                    <label for="Gender" class="form-label text-light">Gender</label>
                                    <select
                                        {...register("gender", { required: true })}
                                        class="form-select input-primary" id="Gender" aria-label="Default select example">
                                        <option selected>Female</option>
                                        <option value="1">Male</option>
                                        <option value="2">Other</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="category" class="form-label text-light">Category</label>
                                    <select
                                        {...register("is_special", { required: true })}
                                        class="form-select input-primary" id="category" aria-label="Default select example">
                                        <option value={0}>Regular</option>
                                        <option value={1}>Special</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="location" class="form-label text-light">Location</label>
                                    <input
                                        {...register("location", { required: true })}
                                        type="address" class="form-control input-primary" id="location" />
                                </div>

                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label text-light">Bio</label>
                            <textarea
                                {...register("bio", { required: true })}
                                class="form-control input-primary" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                    </div>

                    <div className="col-md-4 ">
                        <div className="mb-3 text-center">
                            <label for="image" class="form-label ">
                                {selectedImg && (
                                    <img
                                        alt=""
                                        src={selectedImg}
                                        className="img-fluid rounded-circle"
                                        style={{ height: "250px", width: "250px", margin: "0 auto" }}
                                    />
                                )}
                            </label>
                            <input
                                onChange={(e) => {
                                    setSelectedImg(URL.createObjectURL(e.target.files[0]));
                                    handleImageUpload(e.target.files[0]);
                                }}
                                type="file"
                                accept="image/gif, image/jpeg, image/png"
                                class={selectedImg ? "form-control input-primary" : "form-control input-primary"}
                                id="image" />
                        </div>
                        <div class="mb-3 ">
                            <label for="address" class="form-label text-light">Price</label>
                            <div className="special-input">
                                <input
                                    {...register("price", { required: true })}
                                    type="number" class="form-control  input-primary" id="address" />
                                <span>BDT</span>
                            </div>
                        </div>
                        <div class="mb-3 ">
                            <label for="address" class="form-label text-light">Discount</label>
                            <div className="special-input">
                                <input
                                    {...register("discount", { required: true })}
                                    type="number" class="form-control  input-primary" id="address" />
                                <span>%</span>
                            </div>
                        </div>
                        <div class="mb-3 ">
                            <label for="address" class="form-label text-light">Profit share</label>
                            <div className="special-input">
                                <input
                                    {...register("share", { required: true })}
                                    type="number" class="form-control  input-primary" id="address" />
                                <span>%</span>
                            </div>
                        </div>
                        <div class="mb-3 ">
                            <label for="address" class="form-label text-light">Available Days</label>
                            <div className="dayCardsWrapper">
                                {
                                    days?.map((day, index) => {
                                        return (
                                            <div
                                                // onClick={() => (daysF)}
                                                onClick={() => setAvailableDays([...availableDays, day.name])}
                                                className={availableDays.includes(day.name) ? "dayCard availableDay" : "dayCard"}>{day.name}</div>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </div>

                <div className="pt-3 pb-3 text-center">
                    <div className="text-center fw-bold text-danger pb-2">
                        {
                            errorMsg && <p>{errorMsg}</p>
                        }
                    </div>
                    <button className="btn btn-secondary">
                        Submit
                    </button>
                </div>
            </form>
        </div >
    )
}

export default AddNurse
