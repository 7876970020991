import { Button, useToast } from '@chakra-ui/react'
import React from 'react'
import { deleteCareGiver, editCareGiver } from '../../state/api/care_giver'
import ModalComponent from '../ModalComponent'
import SearchBer from '../SearchBer'
import AddCareGiver from './AddCareGiver'
import CareGiverDetails from './CareGiverDetails'
import EditCareGiver from './EditCareGiver'

function CareGiverListTable({ CareGiver, setDep, setData, pageNumber, setNumberOfPages }) {
    const toast = useToast();
    return (
        <div>
            <div className="d-flex justify-content-between pb-3">
                <h1 className="fw-bold fs-3 pt-3 pb-3">
                    Care Giver List
                </h1>

                {/* search bar */}
                <SearchBer
                    setData={setData}
                    type={"care_givers"}
                    pageNumber={pageNumber}
                    setNumberOfPages={setNumberOfPages}
                />

                <ModalComponent
                    btnClassName={"add-people"}
                    btnIcon={"fas fa-plus-circle"}
                    btnText={"Add Care Giver"}
                    title={"Add Care Giver"}
                >
                    <AddCareGiver setDep={setDep} />
                </ModalComponent>
            </div>

            {/* list table */}
            <table className="table main-table">
                <thead>
                    <tr>
                        <th scope="col">
                            Name and Details
                        </th>
                        <th scope="col">
                            Location
                        </th>
                        <th scope="col">
                            Contact
                        </th>
                        <th scope="col">
                            Status
                        </th>
                        <th scope="col">
                            Action
                        </th>
                        <th scope="col">

                        </th>
                    </tr>
                </thead>
                <div className="pt-3">

                </div>
                <tbody >
                    {
                        CareGiver?.map((CareGiver, index) => {
                            return (
                                <tr >
                                    <td className="userDetailsInTable">
                                        <div className="d-flex align-items-center">
                                            <img src={CareGiver.photo}
                                                className="img-fluid rounded-circle userImage"
                                                alt={CareGiver.name} />
                                            <div className="ms-3">
                                                <h1 className="fw-600 fs-4">{CareGiver.name}</h1>
                                                <p className="text-light">{CareGiver.email}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{CareGiver.address}</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{CareGiver.phone}</h1>
                                    </td>
                                    <td className="activeInactiveCheckBox">
                                        <div className="form-check form-switch">
                                            {
                                                CareGiver.is_active === "1"
                                                    ? <input onChange={async () => {
                                                        await editCareGiver(CareGiver.id, { is_active: false });
                                                        setDep(Math.random());
                                                    }} defaultChecked className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                    : <input onChange={async () => {
                                                        await editCareGiver(CareGiver.id, { is_active: true });
                                                        setDep(Math.random());
                                                    }} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <ModalComponent
                                            btnClassName={"add-people"}
                                            btnIcon={"far fa-eye"}
                                            btnText={"View"}
                                            title={"Care Giver Details"}
                                        >
                                            {/* Care Giver details Component */}
                                            <CareGiverDetails
                                                CareGiver={CareGiver}
                                            />
                                        </ModalComponent>
                                    </td>
                                    <td className="text-center">
                                        <ModalComponent
                                            btnClassName={"btn-editUserProfile"}
                                            btnIcon={"fas fa-edit"}
                                            btnText={"Edit"}
                                            title={"Edit Care Giver"}
                                        >
                                            {/* Edit Care Giver component */}
                                            <EditCareGiver
                                                CareGiver={CareGiver}
                                                setDep={setDep}
                                            />
                                        </ModalComponent>
                                        <br />
                                        <Button
                                            onClick={async () => {
                                                await deleteCareGiver(CareGiver.id);
                                                setDep(Math.random());
                                                toast({
                                                    title: 'Care Giver deleted successfully',
                                                    // description: "We've created your account for you.",
                                                    status: 'success',
                                                    duration: 9000,
                                                    isClosable: true,
                                                    position: 'bottom-right',
                                                });
                                            }}
                                            mt="1"
                                            padding="0px 35px"
                                            fontSize="12"
                                            height="25px"
                                            borderRadius="30px"
                                            color="#000">
                                            <i class="far fa-trash-alt me-2"></i>
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div >
    )
}

export default CareGiverListTable
