import axios from "axios";

const baseURL = "https://parentscarexyz.com/api";

export const allPackages = async (pageNumber) =>
    await axios.get(`/admin/global_packages?page=${ pageNumber }`);

export const addPackage = async (data) =>
    await axios.post(`/admin/global_packages`, data);

export const editPackage = async (id, data) =>
    await axios.put(`/admin/global_packages/${ id }`, data);

export const deletePackage = async (id, data) =>
    await axios.delete(`/admin/global_packages/${ id }`);