import React, { useState } from 'react'
import '../styles/Login.css';
import logo from '../images/Logo 5.png';
import { login } from '../state/api/auth';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from "react-redux";
import { setTrue } from '../state/reducer/login';
import axios from 'axios';
import { setUser } from '../state/reducer/user';
import { useNavigate } from "react-router-dom";

function Login() {
    const [show, setShow] = React.useState(false)
    const handleClick = () => setShow(!show);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [error, setError] = useState(null);
    const [fromData, setFromData] = useState({

    });
    const handleLogin = async () => {
        try {
            const { data } = await login(fromData);

            localStorage.setItem("token", data.accessToken);
            localStorage.setItem("email", data.user.email);
            localStorage.setItem("id", data.user.id);
            localStorage.setItem("name", data.user.name);
            localStorage.setItem("type", data.user.type);
            axios.defaults.headers.common['Authorization'] = `Bearer ${ data.accessToken }`;
            dispatch(setTrue());
            dispatch(setUser(data.user));
            if (data.user.type === "super") {
                navigate.push("/")
            } else {
                navigate.push("/doctors")
            }
        } catch (error) {
            setError(error.message)
        }
    }

    return (
        <div className="loginWrapper">
            <div className="container loginFormWrapper d-flex justify-content-end align-items-center">
                <div className="card p-5 m-5 text-center">
                    <img src={logo} alt="Logo" className="img-fluid" />
                    <h3 className="mt-3 fw-bold">Log In to Dashboard</h3>
                    {/* <p className="text-light">Enter your email and password below</p> */}

                    <from >
                        <div className="mb-3 text-start">
                            <label for="exampleInputEmail1" className="form-label text-light">Email address</label>
                            <input
                                onChange={(e) => setFromData({ ...fromData, email: e.target.value })}
                                type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                        </div>
                        <div className="mb-3 text-start passwordInput">
                            <label for="exampleInputPassword1" className="form-label text-light">Password</label>
                            <input
                                onChange={(e) => setFromData({ ...fromData, password: e.target.value })}
                                type={show ? 'text' : 'password'} className="form-control " id="exampleInputPassword1" />
                            <div onClick={handleClick} className="showHideBtn">
                                {show ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
                            </div>
                        </div>
                        <p className="text-end text-secondary pointer">
                            Forgot password?
                        </p>

                        <input
                            onClick={() => handleLogin()}
                            type="submit" className="btn btn-primary w-100" value={"Login"} />

                        {error &&
                            <h5 className="text-center fs-6 fw-bold mt-3 mb-2 text-danger">
                                Something wrong! Try again later!
                            </h5>
                        }
                    </from>

                </div>
            </div>
        </div>
    )
}

export default Login
