import React, { useEffect, useState } from 'react'
import Pagination from '../../components/Pagination';
import UserListTable from '../../components/User/UserListTable'
import { allUsers } from '../../state/api/user';

function UserList() {

    const [users, setUsers] = useState([]);
    const [dep, setDep] = useState(null);

    // code of pagination start
    const [pageNumber, setPageNumber] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);
    // code of pagination end

    const handleGetAndSetUsers = async (pageNumber) => {
        try {
            const { data } = await allUsers(pageNumber);
            setNumberOfPages(Math.ceil(data.total / data.per_page));
            setUsers(data)
            console.log(data);
        } catch (error) {

        }
    }

    useEffect(() => {
        handleGetAndSetUsers(pageNumber);
    }, [dep,pageNumber]);

    return (
        <div className="container pt-5">
            <h1 className="fw-bold fs-1">User</h1>
            <UserListTable
                users={users}
                setDep={setDep}
            />
            <div className="text-center pb-3">
                    {numberOfPages > 1 && (
                        <Pagination
                            setPageNumber={setPageNumber}
                            numberOfPages={numberOfPages}
                            pageNumber={pageNumber}
                        />
                    )}
                </div>
        </div>
    )
}

export default UserList
