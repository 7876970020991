import React, { useEffect, useState } from 'react'
import DoctorListTable from '../../components/Doctor/DoctorListTable'
import { allDoctors } from '../../state/api/doctor';
import Pagination from '../../components/Pagination';

function DoctorList() {
    const [doctors, setDoctors] = useState([]);
    const [dep, setDep] = useState(null);

    // code of pagination start
    const [pageNumber, setPageNumber] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);
    // code of pagination end

    const handleGetAndSetDoctors = async (pageNumber) => {
        try {
            const { data } = await allDoctors(pageNumber);
            setDoctors(data);
            setNumberOfPages(Math.ceil(data.total / data.per_page));
            console.log(data);
        } catch (error) {

        }
    }

    useEffect(() => {
        handleGetAndSetDoctors(pageNumber);
    }, [dep, pageNumber]);
    return (
        <div className="container pt-5">
            <h1 className="fw-bold fs-1">Doctor</h1>
            <DoctorListTable
                doctors={doctors.data}
                setDep={setDep}
                setData={setDoctors}
                pageNumber={pageNumber}
                setNumberOfPages={setNumberOfPages}
            />
            <div className="text-center pb-3">
                {numberOfPages > 1 && (
                    <Pagination
                        setPageNumber={setPageNumber}
                        numberOfPages={numberOfPages}
                        pageNumber={pageNumber}
                    />
                )}
            </div>
        </div>
    )
}

export default DoctorList
