import React, { useEffect, useState } from 'react'
import CareGiverListTable from '../../components/CareGiver/CareGiverListTable'
import Pagination from '../../components/Pagination';
import { allCareGivers } from '../../state/api/care_giver';

function CareGiverList() {
    const [CareGiver, setCareGiver] = useState([]);
    const [dep, setDep] = useState(null);

    // code of pagination start
    const [pageNumber, setPageNumber] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);
    // code of pagination end

    const handleGetAndSetCareGiver = async (pageNumber) => {
        try {
            const { data } = await allCareGivers(pageNumber);
            setCareGiver(data);
            setNumberOfPages(Math.ceil(data.total / data.per_page));
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleGetAndSetCareGiver(pageNumber);
    }, [dep, pageNumber]);
    return (
        <div className="container pt-5">
            <h1 className="fw-bold fs-1">Care Giver</h1>
            <CareGiverListTable
                CareGiver={CareGiver?.data}
                setDep={setDep}
                setData={setCareGiver}
                pageNumber={pageNumber}
                setNumberOfPages={setNumberOfPages}
            />
            <div className="text-center pb-3">
                {numberOfPages > 1 && (
                    <Pagination
                        setPageNumber={setPageNumber}
                        numberOfPages={numberOfPages}
                        pageNumber={pageNumber}
                    />
                )}
            </div>
        </div>
    )
}

export default CareGiverList
