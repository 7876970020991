import React from 'react'

function ForeignMedicalAssDetails({ request }) {
    return (
        <div className='pb-5'>
            <h1 className='fs-4 fw-bold pb-3'>{request.user.name}</h1>
            {/* Phone Number */}
            <div className="d-flex flex-row flex-1 detailsWrapper">
                <i class="fas fa-phone"></i>
                <div>
                    <h4 className="text-light pb-2">Phone</h4>
                    <h4 className="fw-bold">{request.user.phone}</h4>
                </div>
            </div>

            {/* Data table */}
            <div className="col-md-4 mt-3">
                <table class="table table-bordered">
                    {/* <thead>
                        <tr>
                            <th scope="col"></th>
                            <th scope="col"></th>
                            <th scope="col"></th>
                        </tr>
                    </thead> */}
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>Country</td>
                            <td>{request.location.country}</td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>Visa Processing</td>
                            <td>{request.patient_need_visa_processing === "1" ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>Doctors Appointment</td>
                            <td>{request.patient_need_doctor_appointment === "1" ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>Pre-counselling</td>
                            <td>{request.patient_need_pre_counselling === "1" ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>Ticket Booking</td>
                            <td>{request.patient_need_ticket_booking === "1" ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                            <th scope="row">6</th>
                            <td>Accommodation</td>
                            <td>{request.patient_need_accommodation === "1" ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                            <th scope="row">7</th>
                            <td>Local Transportation</td>
                            <td>{request.patient_need_local_transportation === "1" ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                            <th scope="row">7</th>
                            <td>Air Ambulance</td>
                            <td>{request.patient_need_air_ambulance === "1" ? "Yes" : "No"}</td>
                        </tr>
                        <tr>
                            <th scope="row">8</th>
                            <td>Hospital Admission</td>
                            <td>{request.patient_need_hospital_admission === "1" ? "Yes" : "No"}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ForeignMedicalAssDetails
