import { useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { addTherapistsLocation } from '../../state/api/therapists';

function AddTherapistLocation({ setDep }) {
    const [errorMsg, setErrorMsg] = useState(null);
    const toast = useToast();
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const onSubmit = async (formData) => {
        try {
            const { data } = await addTherapistsLocation(formData);
            console.log(data);
            setDep(data);
            reset();
            toast({
                title: 'Therapist Location added successfully',
                // description: "We've created your account for you.",
                status: 'success',
                duration: 9000,
                position: 'bottom-right',
            });
        } catch (error) {
            setErrorMsg(JSON.stringify(error?.response?.data?.errors));
        }
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit(onSubmit)} action="">
                <div className="row">
                    <div className="col-md-4">

                        <div class="mb-3">
                            <label for="name" class="form-label text-light">Address</label>
                            <input
                                {...register("address", { required: true })}
                                type="name" class="form-control input-primary" id="name" aria-describedby="emailHelp" />
                        </div>
                        <div class="mb-3 ">
                            <label for="Price" class="form-label text-light">Price</label>
                            <div className="special-input">
                                <input
                                    {...register("price", { required: true })}
                                    type="number" class="form-control  input-primary" id="Price" />
                                <span>BDT</span>
                            </div>
                        </div>
                        <div class="mb-3 ">
                            <label for="address" class="form-label text-light">Profit share</label>
                            <div className="special-input">
                                <input
                                    {...register("share", { required: true })}
                                    type="number" class="form-control  input-primary" id="address" />
                                <span>%</span>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-4">

                        <div class="mb-3 ">
                            <label for="Discount" class="form-label text-light">Discount</label>
                            <div className="special-input">
                                <input
                                    {...register("discount", { required: true })}
                                    type="number" class="form-control  input-primary" id="Discount" />
                                <span>%</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="pt-3 pb-3 text-center">
                    <div className="text-center fw-bold text-danger pb-2">
                        {
                            errorMsg && <p>{errorMsg}</p>
                        }
                    </div>
                    <button type='submit' className="btn btn-secondary">
                        Submit
                    </button>
                </div>
            </form>
        </div >
    )
}

export default AddTherapistLocation
