import React, { useState } from 'react'
import { DateRange } from 'react-date-range'

function AddPatientGuidePackage() {
    const [state, setState] = useState([
        {
            startDate: new Date(),
            endDate: null,
            key: 'selection'
        }
    ]);
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-4">

                    <div class="mb-3">
                        <label for="name" class="form-label text-light">Package Name</label>
                        <input type="name" class="form-control input-primary" id="name" aria-describedby="emailHelp" />
                    </div>
                    <div class="mb-3 ">
                        <label for="Price" class="form-label text-light">Price</label>
                        <div className="special-input">
                            <input type="number" class="form-control  input-primary" id="Price" />
                            <span>BDT</span>
                        </div>
                    </div>
                    <div class="mb-3 ">
                        <label for="address" class="form-label text-light">Profit share</label>
                        <div className="special-input">
                            <input type="number" class="form-control  input-primary" id="address" />
                            <span>%</span>
                        </div>
                    </div>
                    <div class="mb-3 ">
                        <label for="Discount" class="form-label text-light">Discount</label>
                        <div className="special-input">
                            <input type="number" class="form-control  input-primary" id="Discount" />
                            <span>%</span>
                        </div>
                    </div>

                </div>
                <div className="col-md-4">

                    <div class="mb-3">
                        <label for="dateOfBirth" class="form-label text-light">Duration</label>
                        {/* <input value={values} onChange={setValues} type="date" class="form-control  input-primary" id="dateOfBirth" /> */}
                        <DateRange
                            editableDateInputs={true}
                            onChange={item => setState([item.selection])}
                            moveRangeOnFirstSelection={false}
                            ranges={state}
                        />
                    </div>

                </div>
            </div>
            <div className="pt-3 pb-3 text-center">
                <button className="btn btn-secondary">
                    Submit
                </button>
            </div>
        </div >
    )
}

export default AddPatientGuidePackage
