import { Button, useToast } from '@chakra-ui/react'
import React from 'react'
import { deleteTherapistsLocation, editTherapistsLocation } from '../../state/api/therapists'
import ModalComponent from '../ModalComponent'
import AddTherapistLocation from './AddTherapistLocation'
import EditTherapistLocation from './EditTherapistLocation'

function TherapistLocationListTable({ setDep, location }) {
    console.log(location);
    const toast = useToast();
    return (
        <div>
            <div className="d-flex justify-content-between pb-3">
                <h1 className="fw-bold fs-3 pt-3 pb-3">
                    Location List
                </h1>

                <ModalComponent
                    btnClassName={"add-people"}
                    btnIcon={"fas fa-plus-circle"}
                    btnText={"Add Location"}
                    title={"Add Location"}
                >
                    <AddTherapistLocation setDep={setDep} />
                </ModalComponent>
            </div>

            {/* list table */}
            <table className="table main-table">
                <thead>
                    <tr>

                        <th scope="col">
                            Address
                        </th>
                        <th scope="col">
                            Fees
                        </th>
                        <th scope="col">
                            Discount
                        </th>
                        <th scope="col">
                            Profit Share
                        </th>
                        <th scope="col">
                            Status
                        </th>
                        <th scope="col">

                        </th>
                    </tr>
                </thead>
                <div className="pt-3">

                </div>
                <tbody >
                    {
                        location?.map((location, index) => {
                            return (
                                <tr >
                                    <td className="userDetailsInTable">
                                        <div className="d-flex align-items-center">
                                            <h1 className="fw-600 fs-4">{location.address} </h1>
                                        </div>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{location.price}</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{location.discount}</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{location.share}</h1>
                                    </td>
                                    <td className="activeInactiveCheckBox">
                                        <div className="form-check form-switch">
                                            {
                                                location.is_active === "1"
                                                    ? <input onChange={async () => {
                                                        await editTherapistsLocation(location.id, { is_active: false });
                                                        setDep(Math.random());
                                                    }} defaultChecked className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                    : <input onChange={async () => {
                                                        await editTherapistsLocation(location.id, { is_active: true });
                                                        setDep(Math.random());
                                                    }} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                            }
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <ModalComponent
                                            btnClassName={"btn-editUserProfile"}
                                            btnIcon={"fas fa-edit"}
                                            btnText={"Edit"}
                                            title={"Edit Therapist Location"}
                                        >
                                            {/* Edit Therapist Location component */}
                                            <EditTherapistLocation
                                                location={location}
                                                setDep={setDep}
                                            />
                                        </ModalComponent>
                                        <br />
                                        <Button
                                            onClick={async () => {
                                                await deleteTherapistsLocation(location.id);
                                                setDep(Math.random());
                                                toast({
                                                    title: "Therapist Location Deleted",
                                                    description: "Therapist Location Deleted Successfully",
                                                    status: "success",
                                                    duration: 9000,
                                                    isClosable: true,
                                                    position: "bottom-right",
                                                });
                                            }}
                                            mt="1"
                                            padding="0px 35px"
                                            fontSize="12"
                                            height="25px"
                                            borderRadius="30px"
                                            color="#000">
                                            <i class="far fa-trash-alt me-2"></i>
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
        </div >
    )
}

export default TherapistLocationListTable
