import React, { useEffect, useState } from 'react'
import CareGiverServiceListTable from '../../components/CareGiverService/CareGiverServiceListTable'
import { allCareGiverServices } from '../../state/api/care_giver';

function CareGiverServiceList() {
    const [CareGiverService, setCareGiverService] = useState([]);
    const [dep, setDep] = useState(null);

    // code of pagination start
    const [pageNumber, setPageNumber] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);
    // code of pagination end

    const handleGetAndSetCareGiverService = async (pageNumber) => {
        try {
            const { data } = await allCareGiverServices(pageNumber);
            setCareGiverService(data)
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleGetAndSetCareGiverService();
    }, [dep]);
    return (
        <div className="container pt-5">
            <h1 className="fw-bold fs-1">Care Giver Service</h1>
            <CareGiverServiceListTable
                CareGiverService={CareGiverService}
                setDep={setDep}
            />
        </div>
    )
}

export default CareGiverServiceList

