import React, { useEffect, useState } from 'react'
import TherapistLocationListTable from '../../components/TherapistLocation/TherapistLocationListTable'
import { allTherapistsLocation } from '../../state/api/therapists';

function TherapistLocationList() {

    const [location, setLocation] = useState();
    const [dep, setDep] = useState(null);

    // code of pagination start
    const [pageNumber, setPageNumber] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);
    // code of pagination end

    const handleGetAndSetLocation = async (pageNumber) => {
        try {
            const { data } = await allTherapistsLocation();
            setLocation(data)
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleGetAndSetLocation();
    }, [dep]);

    return (
        <div className="container pt-5">
            <h1 className="fw-bold fs-1">Therapist</h1>
            <TherapistLocationListTable
                location={location}
                setDep={setDep}
            />
        </div>
    )
}

export default TherapistLocationList
