import React, { useState } from 'react'
import { updateAdminInfo, updateAdminPass } from '../../state/api/auth';

function Admin() {
    const [errorMsg, setErrorMsg] = useState(null);
    const [successInfoMsg, setSuccessInfoMsg] = useState(null);
    const [successPassMsg, setSuccessPassMsg] = useState(null);
    const [info, setInfo] = useState(null);

    const handleInfo = async () => {
        setSuccessInfoMsg(null);
        setErrorMsg(null);
        if (info !== null) {
            try {
                const { data } = await updateAdminInfo(info);
                console.log(data);
                setSuccessInfoMsg("Updated successfully!!")
            } catch (error) {
                setErrorMsg(error.response.data.message);
            }
        } else {
            setErrorMsg("Something is wrong!")
        }
    }

    const [password, setPassword] = useState(null);

    const handlePass = async () => {
        setSuccessPassMsg(null);
        setErrorMsg(null);
        if (password !== null) {
            try {
                const { data } = await updateAdminPass(password);
                console.log(data);
                setSuccessPassMsg("Updated successfully!")
            } catch (error) {
                setErrorMsg(error.response.data.message);
            }
        } else {
            setErrorMsg("Something is wrong!")
        }
    }

    return (
        <div className='pt-5'>
            <h1 className='fs-6 fw-bold'>Account</h1>
            <form action="">
                <div className="row">
                    <div className="col-md-6 pt-4">
                        <div class="mb-3">
                            <label for="name" class="form-label text-light">Name</label>
                            <input
                                autoComplete='off'
                                onChange={(e) => setInfo({ ...info, name: e.target.value })}
                                type="name" class="form-control input-primary" id="name" aria-describedby="emailHelp" />
                        </div>
                        <div class="mb-3">
                            <label for="email" class="form-label text-light">Email</label>
                            <input
                                autoComplete='off'
                                onChange={(e) => setInfo({ ...info, email: e.target.value })}
                                type="email" class="form-control input-primary" id="email" aria-describedby="emailHelp" />
                        </div>

                        <div className="pt-5 pb-3 text-center">
                            <div className="mt-3">
                                {errorMsg && <h6 className='text-danger fw-bold pb-2'>{errorMsg}</h6>}
                            </div>
                            <div className="mt-3">
                                {successInfoMsg && <h6 className='text-success fw-bold pb-2'>{successInfoMsg}</h6>}
                            </div>
                            <button onClick={(e) => {
                                e.preventDefault();
                                handleInfo();
                            }} className="btn btn-secondary">
                                Save Info Setting
                            </button>
                        </div>
                    </div>
                    <div className="col-md-6 pt-4">
                        <div class="mb-3">
                            <label for="password" class="form-label text-light">Old Password</label>
                            <input
                                autoComplete='off'
                                onChange={(e) => setPassword({ ...password, old_password: e.target.value })}
                                type="password" class="form-control  input-primary" id="password" />
                        </div>
                        <div class="mb-3">
                            <label for="CPassword" class="form-label text-light">New Password</label>
                            <input
                                autoComplete='off'
                                onChange={(e) => setPassword({ ...password, new_password: e.target.value })}
                                type="password" class="form-control  input-primary" id="CPassword" />
                        </div>

                        <div className="pt-5 pb-3 text-center">
                            <div className="mt-3">
                                {errorMsg && <h6 className='text-danger fw-bold pb-2'>{errorMsg}</h6>}
                            </div>
                            <div className="mt-3">
                                {successPassMsg && <h6 className='text-success fw-bold pb-2'>{successPassMsg}</h6>}
                            </div>
                            <button onClick={(e) => {
                                e.preventDefault();
                                handlePass();
                            }} className="btn btn-secondary">
                                Save Password Setting
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    )
}

export default Admin
