import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { addDoctorDepartment, allDoctorDepartments, deleteDoctorDepartments } from '../../state/api/doctor';
import slugify from 'slugify';

function Department() {
    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const [dep, setDep] = useState(null);
    const [department, setDepartment] = useState([]);
    const [errorMsg, setErrorMsg] = useState(null);

    const handleAddDepartment = async (body) => {
        try {
            const { data } = await addDoctorDepartment({ ...body, slug: slugify(body.name) });
            console.log(data);
            setDep(Math.random());
            reset();
        } catch (error) {
            setErrorMsg(JSON.stringify(error?.response?.data?.errors));
        }
    };

    const getDepartment = async () => {
        try {
            const { data } = await allDoctorDepartments();
            console.log(data);
            setDepartment(data);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getDepartment();
        return () => {

        }
    }, [dep])
    return (
        <div className='container pt-5'>
            <h1 className="fw-bold fs-1">Doctor Department</h1>


            <div className="row pt-5">
                <form onSubmit={handleSubmit(handleAddDepartment)} className='col-md-4' action="">
                    <h1 className="fs-6 fw-bold">Add Department</h1>
                    <div className="md-3">
                        <label for="addCountry" class="form-label text-light">Department Name</label>
                        <div className="d-flex">
                            <input
                                {...register("name", { required: false })}
                                class="form-control input-primary me-2"
                                type="text"
                                id='addCountry'
                                aria-label="addCountry" />
                            <button
                                class="btn btn-plus"
                                type="submit"><i class="fas fa-plus-circle"></i></button>
                        </div>
                        <div className="text-center fw-bold text-danger pb-2">
                            {
                                errorMsg && <p>{errorMsg}</p>
                            }
                        </div>
                    </div>
                </form>
            </div>

            <div className="pt-3">
                <h1 className="fs-6 fw-bold">List of available Country</h1>
                {/* Data table */}
                <div className="col-md-4 mt-3">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th scope="col">No</th>
                                <th scope="col">Department Name</th>
                                <th scope="col">Availability</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                department?.map((dep, index) => {
                                    return (
                                        <tr>
                                            <th scope="row">{index + 1}</th>
                                            <td>{dep.name}</td>
                                            <td>{dep.is_active === "1" ? "Yes" : "No"}</td>
                                            <td>
                                                <div onClick={async () => {
                                                    await deleteDoctorDepartments(dep.id);
                                                    setDep(Math.random());
                                                }} className="text-center">
                                                    <i class="fas fa-trash"></i>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Department
