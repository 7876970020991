import React from 'react'

function AddHomeSampleCollection() {
    return (
        <div className="container">
            <div className="row">
                {/* 1st column */}
                <div className="col-md-4">

                    <div class="mb-3">
                        <label for="Category" class="form-label text-light">Category</label>
                        <select class="form-select input-primary" id="Category" aria-label="Default select example">
                            <option selected>Dermatology</option>
                            <option selected>Dermatology</option>
                            <option selected>Dermatology</option>
                            <option selected>Dermatology</option>
                            <option selected>Dermatology</option>
                        </select>
                    </div>
                    <div class="mb-3 ">
                        <label for="Price" class="form-label text-light">Price</label>
                        <div className="special-input">
                            <input type="number" class="form-control  input-primary" id="Price" />
                            <span>BDT</span>
                        </div>
                    </div>
                    <div class="mb-3 ">
                        <label for="address" class="form-label text-light">Profit share</label>
                        <div className="special-input">
                            <input type="number" class="form-control  input-primary" id="address" />
                            <span>%</span>
                        </div>
                    </div>

                </div>
                {/* 2nd column */}
                <div className="col-md-4">
                    <div class="mb-3">
                        <label for="SubCategory" class="form-label text-light">Sub Category</label>
                        <select class="form-select input-primary" id="SubCategory" aria-label="Default select example">
                            <option selected>Dermatology</option>
                            <option selected>Dermatology</option>
                            <option selected>Dermatology</option>
                            <option selected>Dermatology</option>
                            <option selected>Dermatology</option>
                        </select>
                    </div>

                    <div class="mb-3 ">
                        <label for="Discount" class="form-label text-light">Discount</label>
                        <div className="special-input">
                            <input type="number" class="form-control  input-primary" id="Discount" />
                            <span>%</span>
                        </div>
                    </div>

                </div>
            </div>
            <div className="pt-3 pb-3 text-center">
                <button className="btn btn-secondary">
                    Submit
                </button>
            </div>
        </div >
    )
}

export default AddHomeSampleCollection
