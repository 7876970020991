import React from 'react'
import '../styles/PeopleBasicInfo.css';

function PeopleBasicInfo({ data }) {
    return (
        <>
            <div className="d-flex align-items-center pb-3">
                <img
                    style={{
                        width: "100px",
                        height: "100px"
                    }}
                    className="img-fluid rounded-circle me-3"
                    src={data?.photo}
                    alt={data?.name} />
                <div >
                    <h2 className='fw-bold'>ID: {data?.id}</h2>
                    <h2 className="fw-bold fs-5">{data?.name}</h2>
                    <p className="text-light">{data?.speciality}</p>
                    <p className="text-light">{data?.is_special === true && "Special"}</p>
                    <p className="text-light">{data?.is_special === false && "Regular"}</p>
                    <h4 className="fw-bold fs-6">{data?.degree}</h4>
                </div>
            </div>
            <div className="d-flex flex-wrap flex-row flex-3 justify-content-between pt-4 pb-4">
                {/* Date of birth */}
                {data?.dob && <div className="d-flex flex-row flex-1 detailsWrapper">
                    <i class="far fa-clock"></i>
                    <div>
                        <h4 className="text-light pb-2">Date Of Birth</h4>
                        <h4 className="fw-bold">{new Date(data?.dob).toDateString()}</h4>
                    </div>
                </div>}
                {/* Email */}
                {data?.email && <div className="d-flex flex-row flex-1 detailsWrapper">
                    <i class="far fa-envelope"></i>
                    <div>
                        <h4 className="text-light pb-2">Email</h4>
                        <h4 className="fw-bold">{data?.email}</h4>
                    </div>
                </div>}
                {/* Phone Number */}
                {data?.phone &&
                    <div className="d-flex flex-row flex-1 detailsWrapper">
                        <i class="fas fa-phone"></i>
                        <div>
                            <h4 className="text-light pb-2">Phone</h4>
                            <h4 className="fw-bold">{data?.phone}</h4>
                        </div>
                    </div>}
                {/* Location */}
                {data?.location &&
                    <div className="d-flex flex-row flex-1 detailsWrapper">
                        <i class="fas fa-map-marker-alt"></i>
                        <div>
                            <h4 className="text-light pb-2">Location</h4>
                            <h4 className="fw-bold">{data?.location?.address || data?.location}</h4>
                        </div>
                    </div>}
                {/* Address */}
                {data?.address &&
                    <div className="d-flex flex-row flex-1 detailsWrapper">
                        <i class="fas fa-map-marker-alt"></i>
                        <div>
                            <h4 className="text-light pb-2">Location</h4>
                            <h4 className="fw-bold">{data?.address}</h4>
                        </div>
                    </div>}
                {/* Available Days */}
                {/* {data?.available &&
                    <div className="d-flex flex-row flex-1 detailsWrapper">
                        <i class="fas fa-calendar-week"></i>
                        <div>
                            <h4 className="text-light pb-2">Available Days</h4>
                            <h4 className="fw-bold">Saturday, Monday, Wednesday</h4>
                        </div>
                    </div>} */}
            </div>
        </>
    )
}

export default PeopleBasicInfo
