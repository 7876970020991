import React, { useEffect, useState } from 'react'
import ModalComponent from '../ModalComponent'
import ServiceRequestDetails from '../ServiceRequest/ServiceRequestDetails'
import Pagination from '../Pagination';
import { allDoctorRequests, editRequest } from '../../state/api/requests';
import { useToast } from '@chakra-ui/react';

function DoctorRequest() {
    const toast = useToast();
    const [requests, setRequests] = useState([]);
    const [dep, setDep] = useState(null);

    // code of pagination start
    const [pageNumber, setPageNumber] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);
    // code of pagination end

    const handleGetAndSetRequest = async () => {
        try {
            const { data } = await allDoctorRequests(pageNumber);
            setRequests(data);
            setNumberOfPages(Math.ceil(data.total / data.per_page));
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleGetAndSetRequest(pageNumber);
    }, [dep, pageNumber]);
    return (
        <div className="container pt-5">
            <h1 className="fw-bold fs-1">Doctor Request</h1>
            {/* main content */}
            <div>
                <div className="d-flex justify-content-between pb-5 pt-4">
                    <h1 className="fw-bold fs-3">
                        Request List
                    </h1>

                    {/* <select class="form-select btn-sorting-report" aria-label="Default select example">
                        <option selected>Doctor</option>
                        <option value="1">Nurse</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                    </select> */}
                </div>

                {/* list table */}
                <table className="table main-table">
                    <thead>
                        <tr>

                            <th scope="col">
                                Name and Details
                            </th>
                            <th scope="col">
                                Sickness Type
                            </th>
                            <th scope="col">
                                Price
                            </th>
                            <th scope="col">
                                Contact
                            </th>
                            <th scope="col">
                                Action
                            </th>
                            <th scope="col">
                                Status
                            </th>
                            <th scope="col">

                            </th>
                        </tr>
                    </thead>
                    <div className="pt-3">

                    </div>
                    <tbody >
                        {
                            requests?.data?.map((request, index) => {
                                return (
                                    <tr >

                                        <td className="userDetailsInTable">
                                            <div className="d-flex align-items-center">
                                                <img src={request.user.photo}
                                                    className="img-fluid rounded-circle userImage"
                                                    alt={request.user.name} />
                                                <div className="ms-3">
                                                    <h1 className="fw-600 fs-4">{request.user.name}</h1>
                                                    <p className="text-light">{request.user.email}</p>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="">
                                            <h1 className="fw-normal fs-5 pt-2">{request.sickness_details}</h1>
                                        </td>
                                        <td className="">
                                            <h1 className="fw-normal fs-5 pt-2">1000.00</h1>
                                        </td>
                                        <td className="">
                                            <h1 className="fw-normal fs-5 pt-2">{request.user.phone}</h1>
                                        </td>
                                        <td>
                                            <ModalComponent
                                                btnClassName={"add-people"}
                                                btnIcon={"far fa-eye"}
                                                btnText={"View"}
                                                title={"Request Details"}
                                            >
                                                {/* Request details Component */}
                                                <ServiceRequestDetails
                                                    request={request}
                                                    setDep={setDep}
                                                />
                                            </ModalComponent>
                                        </td>
                                        <td className="">
                                            <select
                                                onChange={async (e) => {
                                                    await editRequest("doctor_appointments", request.id, { status: e.target.value });
                                                    setDep(Math.random());
                                                    toast({
                                                        title: 'Request status changed successfully',
                                                        // description: "We've created your account for you.",
                                                        status: 'success',
                                                        duration: 9000,
                                                        isClosable: true,
                                                        position: 'bottom-right',
                                                    });
                                                }}
                                                class="form-select statusDropdown text-uppercase" aria-label="Default select example">
                                                <option className='text-light' disabled selected>{request.status}</option>
                                                <option value="pending">Pending</option>
                                                <option value="ongoing">Ongoing</option>
                                                <option value="completed">Complete</option>
                                            </select>
                                        </td>
                                    </tr>
                                )
                            })
                        }

                    </tbody>
                </table>
                <div className="text-center pb-3">
                    {numberOfPages > 1 && (
                        <Pagination
                            setPageNumber={setPageNumber}
                            numberOfPages={numberOfPages}
                            pageNumber={pageNumber}
                        />
                    )}
                </div>
            </div >
        </div>
    )
}

export default DoctorRequest
