import { Button, useToast } from '@chakra-ui/react';
import React from 'react';
import { deleteBlog } from '../../state/api/blog';
import AddDoctor from '../Doctor/AddDoctor';
import DoctorDetails from '../Doctor/DoctorDetails';
import EditDoctor from '../Doctor/EditDoctor';
import ModalComponent from '../ModalComponent';
import AddBlog from './AddBlog';
import BlogDetails from './BlogDetails';
import EditBlog from './EditBlog';

function BlogListTable({ blogs, setDep, setData, pageNumber }) {
    const toast = useToast();
    return (
        <div>
            <div className="d-flex justify-content-between align-items-center pb-3">
                <h1 className="fw-bold fs-3 pt-3 pb-3">
                    Blog List
                </h1>

                <ModalComponent
                    btnClassName={"add-people"}
                    btnIcon={"fas fa-plus-circle"}
                    btnText={"Add Blog"}
                    title={"Create Post "}
                >
                    <AddBlog
                        setDep={setDep} />
                </ModalComponent>
            </div>

            {/* list table */}
            <table className="table main-table">
                <thead>
                    <tr>
                        <th scope="col">
                            Title
                        </th>
                        <th scope="col">
                            Date
                        </th>
                        <th scope="col">
                            Action
                        </th>
                        <th scope="col">

                        </th>
                    </tr>
                </thead>
                <div className="pt-3">

                </div>
                <tbody >
                    {
                        blogs?.map((blog, index) => {
                            return (
                                <tr >
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{blog.title}</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{new Date(blog.created_at).toLocaleDateString()}</h1>
                                    </td>
                                    <td>
                                        <ModalComponent
                                            btnClassName={"add-people"}
                                            btnIcon={"far fa-eye"}
                                            btnText={"View"}
                                            title={"Blog Details"}
                                        >
                                            {/* Blog details Component */}
                                            <BlogDetails
                                                blog={blog}
                                            />
                                        </ModalComponent>
                                    </td>
                                    <td className="text-center">
                                        <ModalComponent
                                            btnClassName={"btn-editUserProfile"}
                                            btnIcon={"fas fa-edit"}
                                            btnText={"Edit"}
                                            title={"Edit Blog"}
                                        >
                                            {/* Edit Blog component */}
                                            <EditBlog
                                                setDep={setDep}
                                                blog={blog}
                                            />
                                        </ModalComponent>
                                        <br />
                                        <Button
                                            onClick={async () => {
                                                await deleteBlog(blog.id);
                                                setDep(Math.random());
                                                toast({
                                                    title: "Blog Deleted",
                                                    description: "Blog Deleted Successfully",
                                                    status: "success",
                                                    duration: 9000,
                                                    isClosable: true,
                                                    position: "bottom-right",
                                                    isCloseButton: true,
                                                    isIcon: true,
                                                    icon: "check-circle",
                                                    className: "toast-success"
                                                });
                                            }}
                                            mt="1"
                                            padding="0px 35px"
                                            fontSize="12"
                                            height="25px"
                                            borderRadius="30px"
                                            color="#000">
                                            <i class="far fa-trash-alt me-2"></i>
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div >
    );
}

export default BlogListTable;
