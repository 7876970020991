import { useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { editCareGiverService } from '../../state/api/care_giver';
import { deleteEmptyFiled } from '../../utils';

function EditCareGiverService({ setDep, service }) {
    const [errorMsg, setErrorMsg] = useState(null);
    const toast = useToast();

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const handleEditCareGiverService = async (body) => {
        try {
            const data = await editCareGiverService(service.id, deleteEmptyFiled(body));
            setDep(Math.random());
            toast({
                title: 'Care Giver Service Edited successfully',
                // description: "We've created your account for you.",
                status: 'success',
                duration: 9000,
                isClosable: false,
                position: 'bottom-right',
            });
            window.location.reload(false);
        } catch (error) {
            setErrorMsg(JSON.stringify(error?.response?.data?.errors));
        }
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit(handleEditCareGiverService)} action="">
                <div className="row">
                    <div className="col-md-4">

                        <div class="mb-3">
                            <label for="name" class="form-label text-light">Service Name</label>
                            <input
                                defaultValue={service.name}
                                {...register("name", { required: false })}
                                type="name" class="form-control input-primary" id="name" aria-describedby="emailHelp" />
                        </div>
                        <div class="mb-3 ">
                            <label for="Discount" class="form-label text-light">Discount</label>
                            <div className="special-input">
                                <input
                                    defaultValue={service.discount}
                                    {...register("discount", { required: false })}
                                    type="number" class="form-control  input-primary" id="Discount" />
                                <span>%</span>
                            </div>
                        </div>

                    </div>
                    {/* 2nd column */}
                    <div className="col-md-4">

                        <div class="mb-3 ">
                            <label for="Price" class="form-label text-light">Price</label>
                            <div className="special-input">
                                <input
                                    defaultValue={service.price}
                                    {...register("price", { required: false })}
                                    type="number" class="form-control  input-primary" id="Price" />
                                <span>BDT</span>
                            </div>
                        </div>
                        <div class="mb-3 ">
                            <label for="address" class="form-label text-light">Profit share</label>
                            <div className="special-input">
                                <input
                                    defaultValue={service.share}
                                    {...register("share", { required: false })}
                                    type="number" class="form-control  input-primary" id="address" />
                                <span>%</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="pt-3 pb-3 text-center">
                    <div className="text-center fw-bold text-danger pb-2">
                        {
                            errorMsg && <p>{errorMsg}</p>
                        }
                    </div>
                    <button type={"submit"} className="btn btn-secondary">
                        Submit
                    </button>
                </div>
            </form>
        </div >
    )
}

export default EditCareGiverService
