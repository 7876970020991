import React from 'react'
import HomeSampleCollectionListTable from '../../components/HomeSampleCollection/HomeSampleCollectionListTable'

function HomeSampleCollectionList() {
    return (
        <div className="container-fluid pt-5">
            <h1 className="fw-bold fs-1">Home Sample Collection</h1>
            <HomeSampleCollectionListTable />
        </div>
    )
}

export default HomeSampleCollectionList
