import { createSlice } from "@reduxjs/toolkit";

const initialState = {

};

const UserSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        setUser: (state, { payload }) => {
            state = payload;
        },
    },
});

export const { setUser } = UserSlice.actions;
export default UserSlice.reducer;