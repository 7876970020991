import React from 'react'
import ModalComponent from '../components/ModalComponent'

function Support() {
    return (
        <div className='container pt-5'>
            <div className='d-flex justify-content-between mb-3'>
                <h1 className="fw-bold fs-3 mt-3">
                    Support
                </h1>

            </div>

            <div className="row pt-3">
                <div class="accordion mt-3" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                            <div class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                <div>
                                    <h6 className='fw-bold'>Aabir Ahammed</h6>
                                    <br />
                                    <h6>0178593859</h6>
                                </div>
                            </div>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                            <div className="text-end">
                                <button className="btn btn-accordion">
                                    solve
                                </button>
                                <button className="ms-3 btn btn-accordion">
                                    Reply
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="accordion mt-3" id="accordionExample">
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="2">
                            <div class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseOne">
                                <div>
                                    <h6 className='fw-bold'>Aabir Ahammed</h6>
                                    <br />
                                    <h6>0178593859</h6>
                                </div>
                            </div>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <strong>This is the first item's accordion body.</strong> It is shown by default, until the collapse plugin adds the appropriate classes that we use to style each element. These classes control the overall appearance, as well as the showing and hiding via CSS transitions. You can modify any of this with custom CSS or overriding our default variables. It's also worth noting that just about any HTML can go within the <code>.accordion-body</code>, though the transition does limit overflow.
                            </div>
                            <div className="text-end">
                                <button className="btn btn-accordion">
                                    solve
                                </button>
                                <ModalComponent
                                    btnClassName={"btn ms-3 btn-accordion"}
                                    btnText={"Reply"}
                                    title={"Reply"}
                                >
                                    <div className='row'>
                                        <h6 className='fw-bold fs-5 mb-2'>Abir ahmed</h6>
                                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Animi magnam veniam quas illo neque soluta iste aut ea ullam dolorem.</p>
                                    </div>
                                    <div className="pb-2 d-flex">
                                        <div class="mb-3 w-75">
                                            <label for="name" class="form-label text-light">Name</label>
                                            <input
                                                type="name" class="form-control border-secondary  input-primary" id="name" aria-describedby="emailHelp" />
                                        </div>
                                        <div className='pt-4'>
                                            <button className="btn mt-2 ms-4 btn-primary">
                                                <i class="far fa-paper-plane"></i>
                                            </button>
                                        </div>
                                    </div>
                                </ModalComponent>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Support
