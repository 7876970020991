import { Button } from '@chakra-ui/button'
import { useToast } from '@chakra-ui/react'
import React from 'react'
import { deleteNurse, editNurse } from '../../state/api/nurse'
import ModalComponent from '../ModalComponent'
import SearchBer from '../SearchBer'
import AddNurse from './AddNurse'
import EditNurse from './EditNurse'
import NurseDetails from './NurseDetails'

function NurseListTable({ setDep, nurses, setData, pageNumber, setNumberOfPages }) {
    const toast = useToast();
    return (
        <div>
            <div className="d-flex justify-content-between pb-3">
                <h1 className="fw-bold fs-3 pt-3 pb-3">
                    Nurse List
                </h1>

                {/* search bar */}
                <SearchBer
                    setData={setData}
                    type={"nurses"}
                    pageNumber={pageNumber}
                    setNumberOfPages={setNumberOfPages}
                />

                <ModalComponent
                    btnClassName={"add-people"}
                    btnIcon={"fas fa-plus-circle"}
                    btnText={"Add Nurse"}
                    title={"Add Nurse"}
                >
                    <AddNurse setDep={setDep} />
                </ModalComponent>
            </div>

            {/* list table */}
            <table className="table main-table">
                <thead>
                    <tr>

                        <th scope="col">
                            Name and Details
                        </th>
                        <th scope="col">
                            Category
                        </th>
                        <th scope="col">
                            Location
                        </th>
                        <th scope="col">
                            Contact
                        </th>
                        <th scope="col">
                            Status
                        </th>
                        <th scope="col">
                            Action
                        </th>
                        <th scope="col">

                        </th>
                    </tr>
                </thead>
                <div className="pt-3">

                </div>
                <tbody >
                    {
                        nurses?.map((nurse, index) => {
                            return (
                                <tr >

                                    <td className="userDetailsInTable">
                                        <div className="d-flex align-items-center">
                                            <img src={nurse.photo}
                                                className="img-fluid rounded-circle userImage"
                                                alt={nurse.name} />
                                            <div className="ms-3">
                                                <h1 className="fw-600 fs-4">{nurse.name}</h1>
                                                <p className="text-light">{nurse.email}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{
                                            nurse.is_special === "1" ? "Special" : "Regular"
                                        }</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{nurse.location}</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{nurse.phone}</h1>
                                    </td>
                                    <td className="activeInactiveCheckBox">
                                        <div className="form-check form-switch">
                                            {
                                                nurse.is_active === "1"
                                                    ? <input onChange={async () => {
                                                        await editNurse(nurse.id, { is_active: false });
                                                        setDep(Math.random());
                                                    }} defaultChecked className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                    : <input onChange={async () => {
                                                        await editNurse(nurse.id, { is_active: true });
                                                        setDep(Math.random());
                                                    }} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <ModalComponent
                                            btnClassName={"add-people"}
                                            btnIcon={"far fa-eye"}
                                            btnText={"View"}
                                            title={"Nurse Details"}
                                        >
                                            {/* Nurse details Component */}
                                            <NurseDetails
                                                nurse={nurse}
                                            />
                                        </ModalComponent>
                                    </td>
                                    <td className="text-center">
                                        <ModalComponent
                                            btnClassName={"btn-editUserProfile"}
                                            btnIcon={"fas fa-edit"}
                                            btnText={"Edit"}
                                            title={"Edit Nurse"}
                                        >
                                            {/* Edit nurse component */}
                                            <EditNurse
                                                setDep={setDep}
                                                nurse={nurse}
                                            />
                                        </ModalComponent>
                                        <br />
                                        <Button
                                            onClick={async () => {
                                                await deleteNurse(nurse.id);
                                                setDep(Math.random());
                                                toast({
                                                    title: "Nurse Deleted",
                                                    description: "Nurse has been deleted successfully",
                                                    status: "success",
                                                    duration: 9000,
                                                    isClosable: true,
                                                    position: "bottom-right",
                                                });
                                            }}
                                            mt="1"
                                            padding="0px 35px"
                                            fontSize="12"
                                            height="25px"
                                            borderRadius="30px"
                                            color="#000">
                                            <i class="far fa-trash-alt me-2"></i>
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div >
    )
}

export default NurseListTable
