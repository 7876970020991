import React from 'react'
import AppointmentCard from '../Cards/AppointmentCard'
import PeopleBasicInfo from '../PeopleBasicInfo'
import UserPrevAppointmentTable from './UserPrevAppointmentTable'

function UserDetails({
    user
}) {
    return (
        <div className="container pb-5">
            <div className="row">
                <div className="col-md-9">
                    <PeopleBasicInfo
                        data={user} />
                </div>
                <div className="col-md-3  appointmentWrapper">
                    <h3 className="fw-bold fs-5">
                        Customer Appointment
                    </h3>
                    {/* <AppointmentCard /> */}
                </div>
            </div>
            <UserPrevAppointmentTable user={user} />
        </div>
    )
}

export default UserDetails
