import axios from "axios";

export const login = async (data) =>
    await axios.post(`/admin/login`, data);

export const updateAdminInfo = async (body) =>
    await axios.post(`/admin/update_admin_info`, body);

export const updateAdminPass = async (body) =>
    await axios.post(`/admin/change_admin_password`, body);

export const updateSubAdminInfo = async (body) =>
    await axios.post(`/admin/update_subadmin_info`, body);

export const updateSubAdminPass = async (body) =>
    await axios.post(`/admin/change_subadmin_password`, body);