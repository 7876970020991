import axios from "axios";

const baseURL = "https://parentscarexyz.com/api";

export const allPatientGuide = async (pageNumber) =>
    await axios.get(`/admin/patient_guides?page=${ pageNumber }`);

export const addPatientGuide = async (data) =>
    await axios.post(`/admin/patient_guides`, data);

export const editPatientGuide = async (id, data) =>
    await axios.put(`/admin/patient_guides/${ id }`, data);



export const deletePatientGuide = async (id) =>
    await axios.delete(`/admin/patient_guides/${ id }`)