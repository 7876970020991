import axios from "axios";

export const allBlogs = async (pageNumber) =>
    await axios.get(`/admin/blogs?page=${ pageNumber }`);

export const addBlogs = async (body) =>
    await axios.post(`/admin/blogs`, body);

export const editBlogs = async (id, body) =>
    await axios.put(`/admin/blogs/${ id }`, body);

export const deleteBlog = async (id) =>
    await axios.delete(`/admin/blogs/${ id }`);