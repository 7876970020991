import { Button } from '@chakra-ui/button'
import { useDisclosure } from '@chakra-ui/hooks'
import { Drawer, DrawerBody, DrawerContent, DrawerHeader, DrawerOverlay } from '@chakra-ui/modal'
import React, { useState } from 'react'
import logo from '../images/Logo 5.png';
import { Link, useHistory } from "react-router-dom";
import '../styles/Sidebar.css'

function Sidebar() {
    // drawer 
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [placement, setPlacement] = React.useState('left');

    // side bar function
    const [dropDown, setDropDown] = useState(null);
    const handleDropDown = (menu) => {
        setDropDown(menu);
    };
    return (
        <div>
            <Button colorScheme='teal' variant='ghost' onClick={onOpen}>
                <i className="fas fa-bars"></i>
            </Button>
            <Drawer placement={placement} onClose={onClose} isOpen={isOpen}>
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader borderBottomWidth='1px'>
                        <img src={logo} className="img-fluid p-2" alt="Logo!" />
                    </DrawerHeader>
                    <DrawerBody>
                        <div
                            className={"sidebar"}
                        >
                            <ul class="side-menu list-unstyled">
                                <li class="">
                                    <Link to="/">
                                        <a>Dashboard</a>
                                    </Link>
                                </li>
                                <li class="">
                                    <Link to="/blogs">
                                        <a>Blogs</a>
                                    </Link>
                                </li>
                                {/* service request dropdown */}
                                {/* <li onClick={() => handleDropDown("service-req")} class="target">
                                    <a>Service Request</a>
                                </li>
                                <div
                                    className={dropDown === "service-req" ? "dropdown-content" : "dropdown-collapse"}
                                >
                                    <li class="">
                                        <Link to="/vendor/request">
                                            <a>Request List</a>
                                        </Link>
                                    </li>
                                    <li class="">
                                        <Link to="/vendor/list">
                                            <a>Deleted Item</a>
                                        </Link>
                                    </li>
                                </div> */}
                                <li class="">
                                    <Link to="/user">
                                        <a>User List</a>
                                    </Link>
                                </li>
                                {/* doctor dropdown */}
                                <li onClick={() => handleDropDown("doctor")} class="target">
                                    <a>Doctor</a>
                                </li>
                                <div
                                    className={dropDown === "doctor" ? "dropdown-content" : "dropdown-collapse"}
                                >
                                    <li class="">
                                        <Link to="/doctors">
                                            <a>Doctor List</a>
                                        </Link>
                                    </li>
                                    <li class="">
                                        <Link to="/service-request/doctor">
                                            <a>Doctor Request</a>
                                        </Link>
                                    </li>
                                    <li class="">
                                        <Link to="/doctors/department">
                                            <a>Department List</a>
                                        </Link>
                                    </li>
                                </div>
                                {/* Home Sample dropdown */}
                                <li onClick={() => handleDropDown("Home Sample")} class="target">
                                    <a>Home Sample</a>
                                </li>
                                <div
                                    className={dropDown === "Home Sample" ? "dropdown-content" : "dropdown-collapse"}
                                >
                                    {/* <li class="">
                                        <Link to="/home-sample-collection">
                                            <a>Home Sample List</a>
                                        </Link>
                                    </li> */}
                                    <li class="">
                                        <Link to="/service-request/home-sample">
                                            <a>Home Sample Request</a>
                                        </Link>
                                    </li>
                                    <li class="">
                                        <Link to="/home-sample-collection/category">
                                            <a>Category List</a>
                                        </Link>
                                    </li>
                                </div>
                                {/* Therapist dropdown */}
                                <li onClick={() => handleDropDown("Therapist")} class="target">
                                    <a>Physiotherapy</a>
                                </li>
                                <div
                                    className={dropDown === "Therapist" ? "dropdown-content" : "dropdown-collapse"}
                                >
                                    <li class="">
                                        <Link to="/therapists">
                                            <a>Therapist List</a>
                                        </Link>
                                    </li>
                                    <li class="">
                                        <Link to="/service-request/therapist">
                                            <a>Therapist Request</a>
                                        </Link>
                                    </li>
                                    <li class="">
                                        <Link to="/therapist/location">
                                            <a>Location List</a>
                                        </Link>
                                    </li>
                                </div>
                                {/* Nurse dropdown */}
                                <li onClick={() => handleDropDown("Nurse")} class="target">
                                    <a>Nurse</a>
                                </li>
                                <div
                                    className={dropDown === "Nurse" ? "dropdown-content" : "dropdown-collapse"}
                                >
                                    <li class="">
                                        <Link to="/nurses">
                                            <a>Nurse List</a>
                                        </Link>
                                    </li>
                                    <li class="">
                                        <Link to="/service-request/nurse">
                                            <a>Nurse Request</a>
                                        </Link>
                                    </li>
                                    <li class="">
                                        <Link to="/nurse/packages">
                                            <a>Package List</a>
                                        </Link>
                                    </li>
                                </div>
                                {/* Patient Guide dropdown */}
                                <li onClick={() => handleDropDown("Patient Guide")} class="target">
                                    <a>Patient Guide</a>
                                </li>
                                <div
                                    className={dropDown === "Patient Guide" ? "dropdown-content" : "dropdown-collapse"}
                                >
                                    <li class="">
                                        <Link to="/patient-guides">
                                            <a>Patient Guide List</a>
                                        </Link>
                                    </li>
                                    <li class="">
                                        <Link to="/service-request/patient-guide">
                                            <a>Patient Guide Request</a>
                                        </Link>
                                    </li>
                                    {/* <li class="">
                                        <Link to="/patient-guide/packages">
                                            <a>Package List</a>
                                        </Link>
                                    </li> */}
                                </div>
                                {/* Care Giver dropdown */}
                                <li onClick={() => handleDropDown("Care Giver")} class="target">
                                    <a>Care Giver</a>
                                </li>
                                <div
                                    className={dropDown === "Care Giver" ? "dropdown-content" : "dropdown-collapse"}
                                >
                                    <li class="">
                                        <Link to="/care-giver">
                                            <a>Care Giver List</a>
                                        </Link>
                                    </li>
                                    <li class="">
                                        <Link to="/service-request/care-giver">
                                            <a>Care Giver Request</a>
                                        </Link>
                                    </li>
                                    <li class="">
                                        <Link to="/care-giver/service">
                                            <a>Service List</a>
                                        </Link>
                                    </li>
                                </div>
                                <li class="">
                                    <Link to="/foreign-medical-assistance">
                                        <a>Foreign Medical</a>
                                    </Link>
                                </li>
                                <li class="">
                                    <Link to="/report">
                                        <a>Report</a>
                                    </Link>
                                </li>
                                {/* Package dropdown */}
                                <li onClick={() => handleDropDown("Package")} class="target">
                                    <a>Package</a>
                                </li>
                                <div
                                    className={dropDown === "Package" ? "dropdown-content" : "dropdown-collapse"}
                                >
                                    <li class="">
                                        <Link to="/package">
                                            <a>Package List</a>
                                        </Link>
                                    </li>
                                    <li class="">
                                        <Link to="/service-request/package">
                                            <a>Package Request List</a>
                                        </Link>
                                    </li>
                                </div>
                                <li class="">
                                    <Link to="/contact">
                                        <a>Contact</a>
                                    </Link>
                                </li>
                                {/* <li class="">
                                    <Link to="/support">
                                        <a>Support</a>
                                    </Link>
                                </li> */}
                                <li class="">
                                    <Link to="/settings">
                                        <a>Settings</a>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>

        </div>
    )
}

export default Sidebar
