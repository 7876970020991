import { Button } from '@chakra-ui/button'
import React from 'react'
import ModalComponent from '../ModalComponent'
import AddPatientGuidePackage from './AddPatientGuidePackage'
import EditPatientGuidePackage from './EditPatientGuidePackage'

function PatientGuidePackageListTable() {
    return (
        <div>
            <div className="d-flex justify-content-between pb-3">
                <h1 className="fw-bold fs-3 pt-3 pb-3">
                    Package List
                </h1>

                <ModalComponent
                    btnClassName={"add-people"}
                    btnIcon={"fas fa-plus-circle"}
                    btnText={"Add Package"}
                    title={"Add Package"}
                >
                    <AddPatientGuidePackage />
                </ModalComponent>
            </div>

            {/* list table */}
            <table className="table main-table">
                <thead>
                    <tr>
                       
                        <th scope="col">
                            Package Name
                        </th>
                        <th scope="col">
                            Category
                        </th>
                        <th scope="col">
                            Duration
                        </th>
                        <th scope="col">
                            Fees
                        </th>
                        <th scope="col">
                            Discount
                        </th>
                        <th scope="col">
                            Profit Share
                        </th>
                        <th scope="col">
                            Status
                        </th>
                        <th scope="col">

                        </th>
                    </tr>
                </thead>
                <div className="pt-3">

                </div>
                <tbody >
                    <tr >
                        
                        <td className="userDetailsInTable">
                            <div className="d-flex align-items-center">
                                <h1 className="fw-600 fs-4">Weekly Package</h1>
                            </div>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">Special</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">7 days</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">180.00</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">1%</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">18%</h1>
                        </td>
                        <td className="activeInactiveCheckBox">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                            </div>
                        </td>
                        <td className="text-center">
                            <ModalComponent
                                btnClassName={"btn-editUserProfile"}
                                btnIcon={"fas fa-edit"}
                                btnText={"Edit"}
                                title={"Edit Patient Guide Package"}
                            >
                                {/* Edit Patient Guide component */}
                                <EditPatientGuidePackage />
                            </ModalComponent>
                            <br />
                            <Button
                                mt="1"
                                padding="0px 35px"
                                fontSize="12"
                                height="25px"
                                borderRadius="30px"
                                color="#000">
                                <i class="far fa-trash-alt me-2"></i>
                                Delete
                            </Button>
                        </td>
                    </tr>
                    <tr >
                        
                        <td className="userDetailsInTable">
                            <div className="d-flex align-items-center">
                                <h1 className="fw-600 fs-4">Weekly Package</h1>
                            </div>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">Special</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">7 days</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">180.00</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">1%</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">18%</h1>
                        </td>
                        <td className="activeInactiveCheckBox">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                            </div>
                        </td>
                        <td className="text-center">
                            <Button
                                padding="0px 35px"
                                fontSize="12"
                                height="25px"
                                borderRadius="30px"
                                color="#000">
                                <i class="fas fa-edit me-2"></i>
                                Edit
                            </Button>
                            <br />
                            <Button
                                mt="1"
                                padding="0px 35px"
                                fontSize="12"
                                height="25px"
                                borderRadius="30px"
                                color="#000">
                                <i class="far fa-trash-alt me-2"></i>
                                Delete
                            </Button>
                        </td>
                    </tr>
                    <tr >
                        
                        <td className="userDetailsInTable">
                            <div className="d-flex align-items-center">
                                <h1 className="fw-600 fs-4">Weekly Package</h1>
                            </div>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">Special</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">7 days</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">180.00</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">1%</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">18%</h1>
                        </td>
                        <td className="activeInactiveCheckBox">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                            </div>
                        </td>
                        <td className="text-center">
                            <Button
                                padding="0px 35px"
                                fontSize="12"
                                height="25px"
                                borderRadius="30px"
                                color="#000">
                                <i class="fas fa-edit me-2"></i>
                                Edit
                            </Button>
                            <br />
                            <Button
                                mt="1"
                                padding="0px 35px"
                                fontSize="12"
                                height="25px"
                                borderRadius="30px"
                                color="#000">
                                <i class="far fa-trash-alt me-2"></i>
                                Delete
                            </Button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div >
    )
}

export default PatientGuidePackageListTable
