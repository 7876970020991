import { Button } from '@chakra-ui/button'
import { useToast } from '@chakra-ui/react'
import React from 'react'
import { deleteNursePackage, editNursePackage } from '../../state/api/nurse'
import ModalComponent from '../ModalComponent'
import AddNursePackage from './AddNursePackage'
import EditNursePackage from './EditNursePackage'

function NursePackageListTable({ nursePackages, setDep }) {
    const toast = useToast();
    return (
        <div>
            <div className="d-flex justify-content-between pb-3">
                <h1 className="fw-bold fs-3 pt-3 pb-3">
                    Package List
                </h1>

                <ModalComponent
                    btnClassName={"add-people"}
                    btnIcon={"fas fa-plus-circle"}
                    btnText={"Add Package"}
                    title={"Add Package"}
                >
                    <AddNursePackage setDep={setDep} />
                </ModalComponent>
            </div>

            {/* list table */}
            <table className="table main-table">
                <thead>
                    <tr>

                        <th scope="col">
                            Package Name
                        </th>
                        <th scope="col">
                            Category
                        </th>
                        <th scope="col">
                            Duration
                        </th>
                        <th scope="col">
                            Fees
                        </th>
                        <th scope="col">
                            Discount
                        </th>
                        <th scope="col">
                            Profit Share
                        </th>
                        <th scope="col">
                            Status
                        </th>
                        <th scope="col">

                        </th>
                    </tr>
                </thead>
                <div className="pt-3">

                </div>
                <tbody >
                    {
                        nursePackages?.map((nursePackage, index) => {
                            return (
                                <tr >
                                    <td className="userDetailsInTable">
                                        <div className="d-flex align-items-center">
                                            <h1 className="fw-600 fs-4">{nursePackage.name}</h1>
                                        </div>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">
                                            {
                                                nursePackage.is_special === "1" ? "Special" : "Regular"
                                            }
                                        </h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{nursePackage.duration} days</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{nursePackage.price}</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{nursePackage.discount}</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{nursePackage.share}</h1>
                                    </td>
                                    <td className="activeInactiveCheckBox">
                                        <div className="form-check form-switch">
                                            {
                                                nursePackage.is_active === "1"
                                                    ? <input onChange={async () => {
                                                        await editNursePackage(nursePackage.id, { is_active: false });
                                                        setDep(Math.random());
                                                    }} defaultChecked className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                    : <input onChange={async () => {
                                                        await editNursePackage(nursePackage.id, { is_active: true });
                                                        setDep(Math.random());
                                                    }} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                            }
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <ModalComponent
                                            btnClassName={"btn-editUserProfile"}
                                            btnIcon={"fas fa-edit"}
                                            btnText={"Edit"}
                                            title={"Edit Nurse Package"}
                                        >
                                            {/* Edit nurse component */}
                                            <EditNursePackage
                                                nursePackage={nursePackage}
                                                setDep={setDep} />
                                        </ModalComponent>
                                        <br />
                                        <Button
                                            onClick={async () => {
                                                await deleteNursePackage(nursePackage.id);
                                                setDep(Math.random());
                                                toast({
                                                    title: "Deleted",
                                                    description: "Nurse Package Deleted Successfully",
                                                    status: "success",
                                                    duration: 9000,
                                                    isClosable: true
                                                });
                                            }}
                                            mt="1"
                                            padding="0px 35px"
                                            fontSize="12"
                                            height="25px"
                                            borderRadius="30px"
                                            color="#000">
                                            <i class="far fa-trash-alt me-2"></i>
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div >
    )
}

export default NursePackageListTable
