
export const calculatePercentage = (price, rate) => {
    return (rate / 100) * price;
}

export const deleteEmptyFiled = (object) => {
    const data = Object.entries(object);
    for (let i = 0; i < data.length; i++) {
        const element = data[i];
        console.log(element);
        if (element[1] == "" || element[1] === "" || element[1] === null) {
            console.log(element[0]);
            delete object[element[0]];
            console.log(object);
        }
    }
    return object;
}

export const getServiceName = (data) => {
    if (data.care_giver_service_id) return "Care Giver";
    if (data.doctor_id) return "Doctor";
    if (data.foreign_medical_location_id) return "Foreign Medical";
    if (data.home_sample_subcategories_id) return "Home Sample";
    if (data.nurse_id) return "Nurse";
    if (data.patient_guide_id) return "Patient Guide";
    if (data.therapist_id) return "Therapist";
    if (data.global_package_id) return "Global Package";
};

export const getServicePath = (data) => {
    if (data.care_giver_service_id) return "care_givers";
    if (data.doctor_id) return "doctors";
    if (data.foreign_medical_location_id) return "foreign_medicals";
    if (data.home_sample_subcategories_id) return "home_samples";
    if (data.nurse_id) return "nurses";
    if (data.patient_guide_id) return "patient_guides";
    if (data.therapist_id) return "therapists";
    if (data.global_package_id) return "Global Package";
};