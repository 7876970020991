import axios from "axios";

export const allDoctors = async (pageNumber) =>
    await axios.get(`/admin/doctors?page=${ pageNumber }`);

export const addDoctor = async (data) =>
    await axios.post(`/admin/doctors`, data);

export const editDoctor = async (id, data) =>
    await axios.put(`/admin/doctors/${ id }`, data);

export const doctorDepartmentByID = async (id) =>
    await axios.get(`/admin/doctor_departments/${ id }`);

export const doctorDepartments = async () =>
    await axios.get(`/admin/doctor_departments`);

export const deleteDoctor = async (id) =>
    await axios.delete(`/admin/doctors/${ id }`);

export const addDoctorDepartment = async (data) =>
    await axios.post(`/admin/doctor_departments`, data);

export const allDoctorDepartments = async () =>
    await axios.get(`/admin/doctor_departments`);

export const deleteDoctorDepartments = async (id) =>
    await axios.delete(`/admin/doctor_departments/${ id }`);
