import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    status: false,
};

const LoginSlice = createSlice({
    name: "login",
    initialState,
    reducers: {
        setTrue: (state) => {
            state.status = true;
        },
        setFalse: (state) => {
            state.status = false;
        },
    },
});

export const { setTrue, setFalse } = LoginSlice.actions;
export default LoginSlice.reducer;