import { Button, useToast } from '@chakra-ui/react'
import React from 'react'
import { deletePackage } from '../../state/api/package'
import ModalComponent from '../ModalComponent'
import AddPackage from './AddPackage'
import EditPackage from './EditPackage'


function PackageListTable({ Packages, setDep }) {
    const toast = useToast();
    return (
        <div>
            <div className="d-flex justify-content-between pb-3">
                <h1 className="fw-bold fs-3 pt-3 pb-3">
                    Package List
                </h1>

                <ModalComponent
                    btnClassName={"add-people"}
                    btnIcon={"fas fa-plus-circle"}
                    btnText={"Add Package"}
                    title={"Add Package"}
                >
                    <AddPackage
                        setDep={setDep}
                    />
                </ModalComponent>
            </div>

            {/* list table */}
            <table className="table main-table">
                <thead>
                    <tr>

                        <th scope="col">
                            Package Name
                        </th>
                        <th scope="col">
                            Validity
                        </th>
                        <th scope="col">
                            Price
                        </th>
                        <th scope="col">
                            Discount
                        </th>
                        <th scope="col">
                            Profit Share
                        </th>
                        <th scope="col">
                            Status
                        </th>
                        <th scope="col">

                        </th>
                    </tr>
                </thead>
                <div className="pt-3">

                </div>
                <tbody >
                    {
                        Packages?.map((packages, index) => {
                            return (
                                <tr >

                                    <td className="userDetailsInTable">
                                        <div className="d-flex align-items-center">
                                            <h1 className="fw-600 fs-4">{packages.name}</h1>
                                        </div>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{packages.validity} Days</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{packages.amount}</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{packages.discount}</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{packages.share}</h1>
                                    </td>
                                    <td className="activeInactiveCheckBox">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <ModalComponent
                                            btnClassName={"btn-editUserProfile"}
                                            btnIcon={"fas fa-edit"}
                                            btnText={"Edit"}
                                            title={"Edit Package"}
                                        >
                                            {/* Edit Package component */}
                                            <EditPackage
                                                packages={packages}
                                                setDep={setDep}
                                            />
                                        </ModalComponent>
                                        <br />
                                        <Button
                                            onClick={async () => {
                                                await deletePackage(packages.id);
                                                setDep(Math.random());
                                                toast({
                                                    title: "Package Deleted",
                                                    description: "Package Deleted Successfully",
                                                    status: "success",
                                                    duration: 9000,
                                                    isClosable: true,
                                                    position: "bottom-right",
                                                });
                                            }}
                                            mt="1"
                                            padding="0px 35px"
                                            fontSize="12"
                                            height="25px"
                                            borderRadius="30px"
                                            color="#000">
                                            <i class="far fa-trash-alt me-2"></i>
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }

                </tbody>
            </table>
        </div >
    )
}

export default PackageListTable
