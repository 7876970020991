import axios from "axios";


export const allDoctorRequests = async (pageNumber) =>
    await axios.get(`/admin/doctor_appointments?page=${ pageNumber }`);

export const allNurseRequests = async (pageNumber) =>
    await axios.get(`/admin/nurse_appointments?page=${ pageNumber }`);

export const allTherapistRequests = async (pageNumber) =>
    await axios.get(`/admin/therapist_appointments?page=${ pageNumber }`);

export const allPatientGuideRequests = async (pageNumber) =>
    await axios.get(`/admin/patient_guide_appointments?page=${ pageNumber }`);

export const allHomeSampleRequests = async (pageNumber) =>
    await axios.get(`/admin/home_sample_appointments?page=${ pageNumber }`);

export const allCareGiverRequests = async (pageNumber) =>
    await axios.get(`/admin/care_giver_appointments?page=${ pageNumber }`);

export const allPackageRequests = async (pageNumber) =>
    await axios.get(`/admin/global_package_subscriptions?page=${ pageNumber }`)

export const editRequest = async (type, id, body) =>
    await axios.put(`/admin/${ type }/${ id }`, body);

export const requestHistory = async (type, id) =>
    await axios.get(`/admin/${ type }/${ id }`)