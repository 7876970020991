import React, { useEffect, useState } from 'react'
import PackageListTable from '../../components/Package/PackageListTable'
import Pagination from '../../components/Pagination';
import { allPackages } from '../../state/api/package';

function PackageList() {
    const [Packages, setPackage] = useState([]);
    const [dep, setDep] = useState(null);

    // code of pagination start
    const [pageNumber, setPageNumber] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);
    // code of pagination end

    const handleGetAndSetPackage = async (pageNumber) => {
        try {
            const { data } = await allPackages(pageNumber);
            setPackage(data);
            setNumberOfPages(Math.ceil(data.total / data.per_page));
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleGetAndSetPackage(pageNumber);
    }, [dep, pageNumber]);
    return (
        <div className="container-fluid pt-5">
            <h1 className="fw-bold fs-1">Packages</h1>
            <PackageListTable
                Packages={Packages?.data}
                setDep={setDep}
            />
            <div className="text-center pb-3">
                {numberOfPages > 1 && (
                    <Pagination
                        setPageNumber={setPageNumber}
                        numberOfPages={numberOfPages}
                        pageNumber={pageNumber}
                    />
                )}
            </div>
        </div>
    )
}

export default PackageList
