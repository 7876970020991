import { Button } from '@chakra-ui/react'
import React from 'react'
import AddCountry from '../ForeignMedicalAss/AddCountry'
import ForeignMedicalAssDetails from '../ForeignMedicalAss/ForeignMedicalAssDetails'
import ModalComponent from '../ModalComponent'
import AddHomeSampleCollection from './AddHomeSampleCollection'
import EditHomeSampleCollection from './EditHomeSampleCollection'

function HomeSampleCollectionListTable() {
    return (
        <div>
            <div className="d-flex justify-content-between pb-3">
                <h1 className="fw-bold fs-3 pt-3 pb-3">
                    Home Sample Collection List
                </h1>

                <ModalComponent
                    btnClassName={"add-people"}
                    btnIcon={"fas fa-plus-circle"}
                    btnText={"Add Home Sample Collection"}
                    title={"Add Home Sample Collection"}
                >
                    <AddHomeSampleCollection />
                </ModalComponent>
            </div>

            {/* list table */}
            <table className="table main-table">
                <thead>
                    <tr>
                       
                        <th scope="col">
                            Category
                        </th>
                        <th scope="col">
                            Sub Category
                        </th>
                        <th scope="col">
                            Price
                        </th>
                        <th scope="col">
                            Discount
                        </th>
                        <th scope="col">
                            Discount
                        </th>
                        <th scope="col">
                            Profit Share
                        </th>
                        <th scope="col">
                            Status
                        </th>
                        <th scope="col">

                        </th>
                    </tr>
                </thead>
                <div className="pt-3">

                </div>
                <tbody >
                    <tr >
                        
                        <td className="userDetailsInTable">
                            <h1 className="fw-normal fs-5 pt-2">HEMATOLOGY</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">BT. CT</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">1000</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">10%</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">10%</h1>
                        </td>
                        <td className="activeInactiveCheckBox">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                            </div>
                        </td>
                        <td className="text-center">
                            <ModalComponent
                                btnClassName={"btn-editUserProfile"}
                                btnIcon={"fas fa-edit"}
                                btnText={"Edit"}
                                title={"Edit Home Sample Collection"}
                            >
                                {/* Edit Home Sample Collection component */}
                                <EditHomeSampleCollection />
                            </ModalComponent>
                            <br />
                            <Button
                                mt="1"
                                padding="0px 35px"
                                fontSize="12"
                                height="25px"
                                borderRadius="30px"
                                color="#000">
                                <i class="far fa-trash-alt me-2"></i>
                                Delete
                            </Button>
                        </td>
                    </tr>
                    <tr >
                        
                        <td className="userDetailsInTable">
                            <h1 className="fw-normal fs-5 pt-2">HEMATOLOGY</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">BT. CT</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">1000</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">10%</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">10%</h1>
                        </td>
                        <td className="activeInactiveCheckBox">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                            </div>
                        </td>
                        <td className="text-center">
                            <ModalComponent
                                btnClassName={"btn-editUserProfile"}
                                btnIcon={"fas fa-edit"}
                                btnText={"Edit"}
                                title={"Edit Home Sample Collection"}
                            >
                                {/* Edit Home Sample Collection component */}
                                <EditHomeSampleCollection />
                            </ModalComponent>
                            <br />
                            <Button
                                mt="1"
                                padding="0px 35px"
                                fontSize="12"
                                height="25px"
                                borderRadius="30px"
                                color="#000">
                                <i class="far fa-trash-alt me-2"></i>
                                Delete
                            </Button>
                        </td>
                    </tr>
                    <tr >
                        
                        <td className="userDetailsInTable">
                            <h1 className="fw-normal fs-5 pt-2">HEMATOLOGY</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">BT. CT</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">1000</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">10%</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">10%</h1>
                        </td>
                        <td className="activeInactiveCheckBox">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                            </div>
                        </td>
                        <td className="text-center">
                            <ModalComponent
                                btnClassName={"btn-editUserProfile"}
                                btnIcon={"fas fa-edit"}
                                btnText={"Edit"}
                                title={"Edit Home Sample Collection"}
                            >
                                {/* Edit Home Sample Collection component */}
                                <EditHomeSampleCollection />
                            </ModalComponent>
                            <br />
                            <Button
                                mt="1"
                                padding="0px 35px"
                                fontSize="12"
                                height="25px"
                                borderRadius="30px"
                                color="#000">
                                <i class="far fa-trash-alt me-2"></i>
                                Delete
                            </Button>
                        </td>
                    </tr>
                    <tr >
                        
                        <td className="userDetailsInTable">
                            <h1 className="fw-normal fs-5 pt-2">HEMATOLOGY</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">BT. CT</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">1000</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">10%</h1>
                        </td>
                        <td className="">
                            <h1 className="fw-normal fs-5 pt-2">10%</h1>
                        </td>
                        <td className="activeInactiveCheckBox">
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                            </div>
                        </td>
                        <td className="text-center">
                            <ModalComponent
                                btnClassName={"btn-editUserProfile"}
                                btnIcon={"fas fa-edit"}
                                btnText={"Edit"}
                                title={"Edit Home Sample Collection"}
                            >
                                {/* Edit Home Sample Collection component */}
                                <EditHomeSampleCollection />
                            </ModalComponent>
                            <br />
                            <Button
                                mt="1"
                                padding="0px 35px"
                                fontSize="12"
                                height="25px"
                                borderRadius="30px"
                                color="#000">
                                <i class="far fa-trash-alt me-2"></i>
                                Delete
                            </Button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div >
    )
}

export default HomeSampleCollectionListTable
