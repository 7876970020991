import { Button, useToast } from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { deleteHomeSampleSubCategory, editHomeSampleSubCategory, HomeSampleCategoryByID } from '../../state/api/home_sample'
import ModalComponent from '../ModalComponent'
import AddHomeSampleSubCategory from './AddHomeSampleSubCategory'
import EditHomeSampleSubCategory from './EditHomeSampleSubCategory'

function HomeSampleSubCategoryListTable({ HomeSampleCategory }) {
    const [homeSampleSubCategories, setHomeSampleSubCategory] = useState([]);
    const [dep, setDep] = useState();
    const toast = useToast();

    const getAndSetCategory = async () => {
        try {
            const { data } = await HomeSampleCategoryByID(HomeSampleCategory.id);
            console.log(data.subcategories);
            setHomeSampleSubCategory(data.subcategories);
            setDep(data);
        } catch (error) {

        }
    }

    useEffect(() => {
        getAndSetCategory();
        return () => {

        };
    }, [dep]);
    return (
        <div>
            <div className="d-flex justify-content-between pb-3">
                <h1 className="fw-bold fs-3 pt-3 pb-3">
                    Home Sample Sub Category List
                </h1>

                <ModalComponent
                    btnClassName={"add-people"}
                    btnIcon={"fas fa-plus-circle"}
                    btnText={"Add Sub Category"}
                    title={"Add Sub Category"}
                >
                    <AddHomeSampleSubCategory
                        setDep={setDep}
                        HomeSampleCategory={HomeSampleCategory}
                    />
                </ModalComponent>
            </div>

            {/* list table */}
            <table className="table main-table">
                <thead>
                    <tr>

                        <th scope="col">
                            Name
                        </th>
                        <th scope="col">
                            Price
                        </th>
                        <th scope="col">
                            Discount
                        </th>
                        <th scope="col">
                            Share
                        </th>
                        <th scope="col">
                            Status
                        </th>
                        <th scope="col">

                        </th>
                    </tr>
                </thead>
                <div className="pt-3">

                </div>
                <tbody >
                    {
                        homeSampleSubCategories?.map((homeSampleSubCategory, index) => {
                            return (
                                <tr >

                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{homeSampleSubCategory.name}</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{homeSampleSubCategory.price}</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{homeSampleSubCategory.discount}</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{homeSampleSubCategory.share}</h1>
                                    </td>
                                    <td className="activeInactiveCheckBox">
                                        <div className="form-check form-switch">
                                            {
                                                homeSampleSubCategory.is_active === "1"
                                                    ? <input onChange={async () => {
                                                        await editHomeSampleSubCategory(homeSampleSubCategory.id, { is_active: false });
                                                        setDep(Math.random());
                                                    }} defaultChecked className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                    : <input onChange={async () => {
                                                        await editHomeSampleSubCategory(homeSampleSubCategory.id, { is_active: true });
                                                        setDep(Math.random());
                                                    }} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                            }
                                        </div>
                                    </td>
                                    <td className="text-center">
                                        <ModalComponent
                                            btnClassName={"btn-editUserProfile"}
                                            btnIcon={"fas fa-edit"}
                                            btnText={"Edit"}
                                            title={"Edit Sub Category"}
                                        >
                                            {/* Edit Sub Category component */}
                                            <EditHomeSampleSubCategory
                                                homeSampleSubCategory={homeSampleSubCategory}
                                                setDep={setDep}
                                            />
                                        </ModalComponent>
                                        <br />
                                        <Button
                                            onClick={async () => {
                                                await deleteHomeSampleSubCategory(homeSampleSubCategory.id);
                                                setDep(Math.random());
                                                toast({
                                                    title: "Deleted",
                                                    description: "Sub Category Deleted Successfully",
                                                    status: "success",
                                                    duration: 9000,
                                                    isClosable: true,
                                                    position: "bottom-right",
                                                });
                                            }}
                                            mt="1"
                                            padding="0px 35px"
                                            fontSize="12"
                                            height="25px"
                                            borderRadius="30px"
                                            color="#000">
                                            <i class="far fa-trash-alt me-2"></i>
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div >
    )
}

export default HomeSampleSubCategoryListTable
