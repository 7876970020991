import React, { useEffect, useState } from 'react'
import { allContact } from '../state/api/contact';

function Contact() {

    const [queries, setQueries] = useState([]);
    const getQueries = async () => {
        try {
            const { data } = await allContact();
            setQueries(data);
            console.log(data)
        } catch (error) {

        }
    }
    useEffect(() => {
        getQueries();
    }, []);
    return (
        <div className='container pt-5'>
            <div className='d-flex justify-content-between mb-3'>
                <h1 className="fw-bold fs-3 mt-3">
                    Contact
                </h1>

            </div>

            <div className="row pt-3">
                {
                    queries?.map((query, index) => {
                        return (
                            <div class="accordion mt-3" id="accordionExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="headingOne">
                                        <div class="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                            <div>
                                                <h6 className='fw-bold'>{query.name}</h6>
                                                <h6>{query.phone}</h6>
                                                <br />
                                                <h6 className='fw-bold'>Query Topic: {query.topic}</h6>
                                            </div>
                                        </div>
                                    </h2>
                                    <div id="collapseOne" class="accordion-collapse collapse " aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                        <div class="accordion-body">
                                            {query.description}
                                        </div>
                                        {/* <div className="text-end">
                                            <button className="btn btn-accordion">
                                                solve
                                            </button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Contact
