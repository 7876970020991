import React from 'react'
import { useParams } from 'react-router-dom'
import CareGiverRequest from '../../components/CareGiver/CareGiverRequest';
import DoctorRequest from '../../components/Doctor/DoctorRequest';
import HomeSampleCollectionRequest from '../../components/HomeSampleCollection/HomeSampleCollectionRequest';
import NurseRequestTable from '../../components/Nurse/NurseRequestTable';
import PatientGuideRequest from '../../components/PatientGuide/PatientGuideRequest';
import TherapistRequest from '../../components/Therapist/TherapistRequest';
import PackageRequest from '../Package/PackageRequest';

function ServiceRequest() {
    const { typeOf } = useParams();
    return (
        <div>
            {typeOf === "doctor" && <DoctorRequest />}
            {typeOf === "home-sample" && <HomeSampleCollectionRequest />}
            {typeOf === "nurse" && <NurseRequestTable />}
            {typeOf === "patient-guide" && <PatientGuideRequest />}
            {typeOf === "therapist" && <TherapistRequest />}
            {typeOf === "package" && <PackageRequest />}
            {typeOf === "care-giver" && <CareGiverRequest />}
        </div>
    )
}

export default ServiceRequest
