import React, { useState } from 'react'
import dateFormat, { masks } from "dateformat";
import { useForm } from 'react-hook-form';
import { editRequest } from '../../state/api/requests';
import { calculatePercentage } from '../../utils';
import { useToast } from '@chakra-ui/react';

function ServiceRequestDetails({ request, setDep }) {
    const toast = useToast();

    const [errorMsg, setErrorMsg] = useState(null);
    const [successMsg, setSuccessMsg] = useState(null);
    const [formData, setFormData] = useState({

    });

    const { register, handleSubmit, reset, formState: { errors } } = useForm();
    const handleAppointDoctor = async (e) => {
        e.preventDefault();
        try {
            const data = await editRequest("doctor_appointments", request.id, formData);
            toast({
                title: 'Updated successfully',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
            setFormData({});
            setDep(Math.random());
        } catch (error) {
            setErrorMsg(JSON.stringify(error?.response?.data?.errors));
        }
    };

    const handleAppointCareGiver = async (e) => {
        e.preventDefault();
        try {
            const data = await editRequest("care_giver_appointments", request.id, formData);
            toast({
                title: 'Updated successfully',
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
            setFormData({});
            setDep(Math.random());
        } catch (error) {
            setErrorMsg(JSON.stringify(error?.response?.data?.errors));
        }
    };

    return (
        <div>
            <div className="row border rounded">
                <div className="col-md-4 pt-3 pb-3">
                    <h1 className="fs-4 fw-bold pb-2">User Info</h1>
                    <h6 className="text-light pb-1">{request.user_id}</h6>
                    <h4 className="fs-6 fw-bold pb-1">{request.user.name}</h4>
                    <h6 className="text-light pb-1">{request.user.email}</h6>
                    <h6 className="text-light pb-1">{request.user.phone}</h6>
                </div>
                <div className="col-md-4 pt-3 pb-3">
                    <h1 className="fs-4 fw-bold pb-2">Service Giver</h1>
                    {request.doctor &&
                        <>
                            <h6 className="text-light pb-1">{request?.doctor?.id}</h6>
                            <h4 className="fs-6 fw-bold pb-1">{request?.doctor?.name}</h4>
                            <h6 className="text-light pb-1">{request?.doctor?.speciality}</h6>
                            <h6 className="text-light pb-1">{request?.doctor?.email}</h6>
                        </>
                    }
                    {request.nurse &&
                        <>
                            <h6 className="text-light pb-1">{request?.nurse?.id}</h6>
                            <h4 className="fs-6 fw-bold pb-1">{request?.nurse?.name}</h4>
                            <h6 className="text-light pb-1">{request.nurse.is_special === "1" ? "Special" : "Regular"}</h6>
                            <h6 className="text-light pb-1">{request?.nurse?.email}</h6>
                        </>
                    }
                    {request.therapist &&
                        <>
                            <h6 className="text-light pb-1">{request?.therapist?.id}</h6>
                            <h4 className="fs-6 fw-bold pb-1">{request?.therapist?.name}</h4>
                            <h6 className="text-light pb-1">{request?.therapist?.email}</h6>
                            <h6 className="text-light pb-1">{request?.therapist?.phone}</h6>
                        </>
                    }
                    {request.patient_guide &&
                        <>
                            <h6 className="text-light pb-1">{request?.patient_guide?.id}</h6>
                            <h4 className="fs-6 fw-bold pb-1">{request?.patient_guide?.name}</h4>
                            <h6 className="text-light pb-1">{request?.patient_guide?.email}</h6>
                            <h6 className="text-light pb-1">{request?.patient_guide?.phone}</h6>
                        </>
                    }
                    {request.subcategory &&
                        <>
                            <h6 className="text-light pb-1">{request?.subcategory?.id}</h6>
                            <h4 className="fs-6 fw-bold pb-1">{request?.subcategory?.name}</h4>
                        </>
                    }
                </div>
                <div className="col-md-4 pt-3 pb-3">
                    <h1 className="fs-4 fw-bold pb-2">Appointment Info</h1>
                    <h4 className="fs-6 fw-bold pb-1">Appointment Booking Time</h4>
                    <h6 className="text-light pb-1">{dateFormat(request.expected_date_time, "dddd, mmmm dS, yyyy, h:MM:ss TT")}</h6>
                    <h4 className="fs-6 fw-bold pb-1">Appointment Taking Time</h4>
                    <h6 className="text-light pb-1">{dateFormat(request.appointment_date_time, "dddd, mmmm dS, yyyy, h:MM:ss TT")}</h6>
                </div>
            </div>

            {/* update doctor appointment  */}
            {
                request?.doctor &&
                <div className="row border rounded mt-3">
                    <form action="">
                        <div className='d-flex pt-3 pb-3'>
                            <div class="mb-3 col-md-4 ">
                                <label for="id" class="form-label text-light">ID</label>
                                <input
                                    onChange={(e) => setFormData({ ...formData, doctor_id: e.target.value })}
                                    type="text" class="form-control input-primary" id="id" aria-describedby="emailHelp" />
                            </div>
                            <div class="mb-3 ms-3 col-md-4 ">
                                <label for="Select Time" class="form-label text-light">Select Time</label>
                                <input
                                    onChange={(e) => setFormData({ ...formData, appointment_date_time: e.target.value })}
                                    type="datetime-local" class="form-control  input-primary" id="Select Time" />
                            </div>
                            <div className=" col-md-4 pt-3 pb-3 text-center">
                                <div className="text-center fw-bold text-danger pb-2">
                                    {
                                        errorMsg && <p>{errorMsg}</p>
                                    }
                                </div>
                                <button onClick={(e) => handleAppointDoctor(e)} type="submit" className="btn btn-secondary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div >
            }


            {
                request?.care_giver_service_id &&
                <div className="row border rounded mt-3">
                    <form action="">
                        <div className='d-flex pt-3 pb-3'>
                            <div class="mb-3 col-md-4 ">
                                <label for="id" class="form-label text-light">ID</label>
                                <input
                                    onChange={(e) => setFormData({ ...formData, care_giver_id: e.target.value })}
                                    type="text" class="form-control input-primary" id="id" aria-describedby="emailHelp" />
                            </div>
                            <div class="mb-3 ms-3 col-md-4 ">
                                <label for="Select Time" class="form-label text-light">Select Time</label>
                                <input
                                    onChange={(e) => setFormData({ ...formData, appointment_date: e.target.value })}
                                    type="date" class="form-control  input-primary" id="Select Time" />
                            </div>
                            <div className=" col-md-4 pt-3 pb-3 text-center">
                                <div className="text-center fw-bold text-danger pb-2">
                                    {
                                        errorMsg && <p>{errorMsg}</p>
                                    }
                                </div>
                                <button onClick={(e) => handleAppointCareGiver(e)} type="submit" className="btn btn-secondary">
                                    Submit
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            }

            <h1 className="fs-3 fw-bold pt-3 pb-2">Service Info</h1>

            {/* service info table */}
            <div className="col-md-12">
                <table class="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Service Name</th>
                            {!request.package &&
                                <>
                                    <th scope="col">Gender</th>
                                    <th scope="col">Age</th>
                                </>
                            }
                            <th scope="col">Price</th>
                            <th scope="col">Discount</th>
                            <th scope="col">Stock Share</th>
                            <th scope="col">Total</th>
                            {/* <th scope="col">Payment Method</th> */}
                            <th scope="col">Payment Status</th>
                            <th scope="col">Transaction ID</th>
                            <th scope="col">Status</th>
                            <th scope="col">Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>
                                {request.doctor && "Doctor Appointment"}
                                {request.nurse && "Nurse Appointment"}
                                {request.therapist && "Therapist Appointment"}
                                {request.patient_guide && "Patient Guide Appointment"}
                                {request.subcategory && "Home Sample Appointment"}
                                {request.package && "Global Package Appointment"}
                                {request.care_giver_service_id && "Care Giver Appointment"}
                            </td>
                            {!request.package &&
                                <>
                                    <td>{request.patient_gender}</td>
                                    <td>{request.patient_age}</td>
                                </>
                            }
                            <td>{request?.price}</td>
                            <td>
                                {calculatePercentage(request?.price, request?.discount)}
                            </td>
                            <td>
                                {calculatePercentage(request?.order.amount, request?.share)}
                            </td>
                            <td>{request?.order.amount}</td>
                            {/* <td>Card</td> */}
                            <td>{request?.order.status}</td>
                            <td>{request?.order.transaction_id}</td>
                            <td>{request?.status}</td>
                            <td>{new Date(request?.created_at).toLocaleDateString()}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div >
    )
}

export default ServiceRequestDetails
