import axios from "axios";

const baseURL = "https://parentscarexyz.com/api";

export const allCareGivers = async (pageNumber) =>
    await axios.get(`/admin/care_givers?page=${ pageNumber }`);

export const addCareGiver = async (data) =>
    await axios.post(`/admin/care_givers`, data);

export const editCareGiver = async (id, data) =>
    await axios.put(`/admin/care_givers/${ id }`, data);

export const deleteCareGiver = async (id, data) =>
    await axios.delete(`/admin/care_givers/${ id }`);

export const allCareGiverServices = async (pageNumber) =>
    await axios.get(`/admin/care_giver_services?page=${ pageNumber }`);

export const addCareGiverService = async (data) =>
    await axios.post(`/admin/care_giver_services`, data);

export const editCareGiverService = async (id, data) =>
    await axios.put(`/admin/care_giver_services/${ id }`, data);

export const deleteCareGiverService = async (id, data) =>
    await axios.delete(`/admin/care_giver_services/${ id }`);