import React from 'react'
import Admin from '../../components/Settings/Admin'
import SubAdmin from '../../components/Settings/SubAdmin'

function Settings() {
    return (
        <div className="container pt-5">
            <h1 className="fw-bold fs-1">Settings</h1>
            <div className="pt-3">
                <ul class="nav nav-tabs settings-tab" id="myTab" role="tablist">
                    <li class="nav-item w-50" role="presentation">
                        <button class="nav-link active w-100" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">
                            Admin
                        </button>
                    </li>
                    <li class="nav-item w-50" role="presentation">
                        <button class="nav-link w-100" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">
                            Sub-Admin
                        </button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <Admin />
                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <SubAdmin />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Settings
