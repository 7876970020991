import React from 'react'
import NursePackageListTable from '../../components/NursePackage/NursePackageListTable'
import PatientGuidePackageListTable from '../../components/PatientGuidePackage/PatientGuidePackageListTable'

function PatientGuidePackageList() {
    return (
        <div className="container-fluid pt-5">
            <h1 className="fw-bold fs-1">Patient Guide</h1>
            <PatientGuidePackageListTable />
        </div>
    )
}

export default PatientGuidePackageList

