import { Button } from '@chakra-ui/react'
import React from 'react'
import ModalComponent from '../ModalComponent'
import AddTherapist from '../Therapist/AddTherapist'
import EditTherapist from '../Therapist/EditTherapist'
import TherapistDetails from '../Therapist/TherapistDetails'
import AddCountry from './AddCountry'
import ForeignMedicalAssDetails from './ForeignMedicalAssDetails'

function ForeignMedicalAssListTable({ foreignMedicalRequests, setDep }) {
    return (
        <div>
            <div className="d-flex justify-content-between pb-3">
                <h1 className="fw-bold fs-3 pt-3 pb-3">
                    Foreign Medical Appointment List
                </h1>

                <ModalComponent
                    btnClassName={"add-people"}
                    btnIcon={"fas fa-plus-circle"}
                    btnText={"Add Country"}
                    title={"Add Country"}
                >
                    <AddCountry />
                </ModalComponent>
            </div>

            {/* list table */}
            <table className="table main-table">
                <thead>
                    <tr>
                        <th scope="col">
                            Name and Details
                        </th>
                        <th scope="col">
                            Prefered Country
                        </th>
                        <th scope="col">
                            Passport
                        </th>
                        <th scope="col">
                            Visa
                        </th>
                        <th scope="col">
                            Action
                        </th>
                        {/* <th scope="col">

                        </th> */}
                    </tr>
                </thead>
                <div className="pt-3">

                </div>
                <tbody >
                    {
                        foreignMedicalRequests?.map((request, index) => {
                            return (
                                <tr >
                                    <td className="userDetailsInTable">
                                        <div className="d-flex align-items-center">
                                            <img src={request.user.photo}
                                                className="img-fluid rounded-circle userImage"
                                                alt={request.user.name} />
                                            <div className="ms-3">
                                                <h1 className="fw-600 fs-4">{request.user.name}</h1>
                                                <p className="text-light">{request.user.email}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{request.location.country}</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">YES</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{request.patient_have_visa === "1" ? "Yes" : "No"}</h1>
                                    </td>
                                    <td>
                                        <ModalComponent
                                            btnClassName={"add-people"}
                                            btnIcon={"far fa-eye"}
                                            btnText={"View"}
                                            title={"View Foreign Medical Appointment"}
                                        >
                                            <ForeignMedicalAssDetails
                                                request={request}
                                            />
                                        </ModalComponent>
                                    </td>
                                    {/* <td className="text-center">
                                        <br />
                                        <Button
                                            mt="1"
                                            padding="0px 35px"
                                            fontSize="12"
                                            height="25px"
                                            borderRadius="30px"
                                            color="#000">
                                            <i class="far fa-trash-alt me-2"></i>
                                            Delete
                                        </Button>
                                    </td> */}
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div >
    )
}

export default ForeignMedicalAssListTable
