import React, { useState } from 'react'
import ReportTable from '../../components/Report/ReportTable'
import '../../styles/Report.css';
import CsvDownloader from 'react-csv-downloader';

function Report() {
    const columns = [
        {
            id: 'first',
            displayName: 'User Name'
        },
        {
            id: 'second',
            displayName: 'Patient Name'
        },
        {
            id: 'third',
            displayName: 'Service Name'
        },
        {
            id: 'four',
            displayName: 'Price'
        },
        {
            id: 'five',
            displayName: 'Discount'
        },
        {
            id: 'six',
            displayName: 'Stock Share'
        },
        {
            id: 'seven',
            displayName: 'Payment Status'
        },
        {
            id: 'eight',
            displayName: 'Payment Method'
        },
        {
            id: 'nine',
            displayName: 'Transaction ID'
        },
        {
            id: 'ten',
            displayName: 'Appointment Status'
        },
        {
            id: 'eleven',
            displayName: 'Date'
        },
    ];

    const [datas, setDatas] = useState([]);
    return (
        <div className="container-fluid pt-5">
            <div className="d-flex justify-content-between mb-3">
                <h1 className="fw-bold fs-3 mt-3">
                    Report
                </h1>

                {/* <button className="add-people">
                    <i class="fas fa-file-download me-2"></i>
                    Download As
                </button> */}
                <CsvDownloader
                    filename={`${ datas[0]?.third } report ${ new Date() }` || `Report ${ new Date() }`}
                    extension=".csv"
                    separator=";"
                    // wrapColumnChar="'"
                    columns={columns}
                    datas={datas}
                    className='add-people'
                    text="DOWNLOAD" />
            </div>
            <ReportTable setDatas={setDatas} datas={datas} />
        </div>
    )
}

export default Report
