import React, { useEffect, useState } from 'react'
import DashboardCard from '../../components/Cards/DashboardCard'
import { allDashboardData } from '../../state/api/dashboard';

function Dashboard() {

    const [dashboardData, setDashboardData] = useState('');
    const [doctors, setDoctors] = useState({});
    const [nurses, setNurses] = useState({});
    const [therapists, setTherapists] = useState({});
    const [patientGuides, setPatientGuides] = useState({});
    const [careGivers, setCareGivers] = useState({});
    const [homeSamples, setHomeSamples] = useState({});
    const [earnings, setEarnings] = useState({});
    const [revenue, setRevenue] = useState({});
    const [packages, setPackages] = useState([]);

    const handleGetAndSetDashboardData = async () => {
        try {
            const { data } = await allDashboardData();
            console.log(data);
            setDashboardData(dashboardData);
            setDoctors(data.doctors);
            setNurses(data.nurses);
            setTherapists(data.therapists);
            setPatientGuides(data.patient_guides);
            setCareGivers(data.care_givers);
            setHomeSamples(data.home_samples);
            setEarnings(data.earning);
            setRevenue(data.revenue);
            setPackages(data.packages);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleGetAndSetDashboardData();
        return () => {

        };
    }, []);
    return (
        <div className='container'>
            <div className="row">
                <div className="col-md-6">
                    <h3 className='pt-2 pb-2 fs-5 fw-bold'>Earnings</h3>
                    <DashboardCard
                        size={"md"}
                        title={'Total Earning'}
                        mainValue={earnings.total}
                        otherInfo={
                            [
                                {
                                    title: "Doctor",
                                    value: earnings.doctor
                                },
                                {
                                    title: "Nurse",
                                    value: earnings.nurse
                                },
                                {
                                    title: "Home Sample",
                                    value: earnings.home_sample
                                },
                                {
                                    title: "Care Giver",
                                    value: earnings.care_giver
                                },
                                {
                                    title: "Patient Guide",
                                    value: earnings.patient_guide
                                },
                                {
                                    title: "Therapist",
                                    value: earnings.therapist
                                },
                                {
                                    title: "Global Package",
                                    value: earnings.global_package
                                },
                            ]
                        }
                    />
                </div>
                <div className="col-md-6">
                    <h3 className='pt-2 pb-2 fs-5 fw-bold'>Revenue</h3>
                    <DashboardCard
                        size={"md"}
                        title={'Total Revenue'}
                        mainValue={revenue.total}
                        otherInfo={
                            [
                                {
                                    title: "Doctor",
                                    value: revenue.doctor
                                },
                                {
                                    title: "Nurse",
                                    value: revenue.nurse
                                },
                                {
                                    title: "Home Sample",
                                    value: revenue.home_sample
                                },
                                {
                                    title: "Care Giver",
                                    value: revenue.care_giver
                                },
                                {
                                    title: "Patient Guide",
                                    value: revenue.patient_guide
                                },
                                {
                                    title: "Therapist",
                                    value: revenue.therapist
                                },
                                {
                                    title: "Global Package",
                                    value: revenue.global_package
                                },
                            ]
                        }
                    />
                </div>
            </div>
            <div className="row">
                <h3 className='pt-2 pb-2 fs-5 fw-bold'>Total Services</h3>
                <div className="d-flex flex-wrap justify-content-between">
                    <DashboardCard
                        size={"sm"}
                        title={'Doctor'}
                        mainValue={doctors?.total}
                        otherInfo={
                            [
                                {
                                    title: "Active",
                                    value: doctors?.active
                                },
                                {
                                    title: "Inactive",
                                    value: doctors?.inactive
                                },
                            ]
                        }
                    />
                    <DashboardCard
                        size={"sm"}
                        title={'Nurse'}
                        mainValue={nurses?.total}
                        otherInfo={
                            [
                                {
                                    title: "Active",
                                    value: nurses?.active
                                },
                                {
                                    title: "Inactive",
                                    value: nurses?.inactive
                                },
                            ]
                        }
                    />
                    <DashboardCard
                        size={"sm"}
                        title={'Therapist'}
                        mainValue={therapists?.total}
                        otherInfo={
                            [
                                {
                                    title: "Active",
                                    value: therapists?.active
                                },
                                {
                                    title: "Inactive",
                                    value: therapists?.inactive
                                },
                            ]
                        }
                    />
                    <DashboardCard
                        size={"sm"}
                        title={'Assistant'}
                        mainValue={patientGuides?.total}
                        otherInfo={
                            [
                                {
                                    title: "Active",
                                    value: patientGuides?.active
                                },
                                {
                                    title: "Inactive",
                                    value: patientGuides?.inactive
                                },
                            ]
                        }
                    />
                    <DashboardCard
                        size={"sm"}
                        title={'Care Giver'}
                        mainValue={careGivers?.total}
                        otherInfo={
                            [
                                {
                                    title: "Active",
                                    value: careGivers?.total
                                },
                                {
                                    title: "Inactive",
                                    value: careGivers?.inactive
                                },
                            ]
                        }
                    />
                    <DashboardCard
                        size={"sm"}
                        title={'Home Sample'}
                        mainValue={homeSamples?.total}
                        otherInfo={
                            [
                                {
                                    title: "Active",
                                    value: homeSamples?.active
                                },
                                {
                                    title: "Inactive",
                                    value: homeSamples?.inactive
                                },
                            ]
                        }
                    />
                </div>
            </div>
            <div className="row">
                <h3 className='pt-2 pb-2 fs-5 fw-bold'>Package</h3>
                <div className="d-flex flex-wrap justify-content-between">
                    {
                        packages?.map((pack, index) => {
                            console.log(pack)
                            return (
                                <DashboardCard
                                    size={"sm"}
                                    title={pack.name}
                                    mainValue={pack.total}
                                    otherInfo={
                                        [
                                            {
                                                title: "Completed",
                                                value: pack.completed
                                            },
                                            {
                                                title: "On-Going",
                                                value: pack.ongoing
                                            },
                                        ]
                                    }
                                />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default Dashboard
