import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { addHomeSampleCategory } from '../../state/api/home_sample';
import Slugify from 'slugify';
import { useToast } from '@chakra-ui/react';

function AddHomeSampleCategory({ setDep }) {
    const [errorMsg, setErrorMsg] = useState(null);
    const toast = useToast();

    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const onSubmit = async (body) => {
        try {
            const data = await addHomeSampleCategory({ ...body, slug: Slugify(body.name) });
            setDep(Math.random());
            reset();
            toast({
                title: 'Home Sample Category added successfully',
                // description: "We've created your account for you.",
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        } catch (error) {
            setErrorMsg("Something went wrong! Try again later!");
        }
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit(onSubmit)} action="">
                <div className="row">
                    <div className="col-md-8">
                        <div className="d-flex">
                            {/* first column */}
                            <div className="w-50 ">
                                <div class="mb-3">
                                    <label for="name" class="form-label text-light">Name</label>
                                    <input
                                        {...register("name", { required: true })}
                                        type="name" class="form-control input-primary" id="name" aria-describedby="emailHelp" />
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="pt-3 pb-3 text-center">
                    <div className="text-center fw-bold text-danger pb-2">
                        {
                            errorMsg && <p>{errorMsg}</p>
                        }
                    </div>
                    <button type="submit" className="btn btn-secondary">
                        Submit
                    </button>
                </div>
            </form>
        </div >
    )
}

export default AddHomeSampleCategory
