import React, { useState } from "react";
import { getAdminReport } from "../../state/api/report";

function ReportTable({ setDatas, datas }) {
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [sortBy, setSortBy] = useState("care_giver");
  const [reports, setReports] = useState([]);

  const getServiceName = (row) => {
    if (row.care_giver_service_id) return "Care Giver";
    if (row.doctor_id) return "Doctor";
    if (row.foreign_medical_location_id) return "Foreign Medical";
    if (row.home_sample_subcategories_id) return "Home Sample";
    if (row.nurse_id) return "Nurse";
    if (row.patient_guide_id) return "Patient Guide";
    if (row.therapist_id) return "Therapist";
    if (row.global_package_id) return "Global Package";
  };

  const getData = async () => {
    try {
      setDatas([]);
      const { data } = await getAdminReport(dateFrom, dateTo, sortBy);

      setReports(data);
      data.map(async row => await setDatas([...datas, {
        "first": row.patient_name,
        "second": row.user.name,
        "third": getServiceName(row),
        "four": row?.price || "N/A",
        "five": row?.discount || "N/A",
        "six": row?.share || "N/A",
        "seven": row.order?.status || "N/A",
        "eight": "Unknown",
        "nine": row.order?.transaction_id || "N/A",
        "ten": row.status || "N/A",
        "eleven": new Date(row.created_at).toDateString(),
      }]));
      console.log(data);
    } catch (error) {

    }
  };

  return (
    <div className="mt-3">
      <div className="d-flex">
        <h4 className="fs-5 fw-bold me-5">From</h4>
        <input
          value={dateFrom}
          onChange={(e) => setDateFrom(e.target.value)}
          type="date"
        />
        <h4 className="ms-4 fs-5 fw-bold me-5">To</h4>
        <input
          value={dateTo}
          onChange={(e) => setDateTo(e.target.value)}
          type="date"
        />
      </div>
      <div className="d-flex mt-4">
        <h4 className="fs-5 fw-bold me-5 pt-2">Sort by:</h4>
        <select
          value={sortBy}
          onChange={(e) => setSortBy(e.target.value)}
          class="form-select btn-sorting-report"
          aria-label="Default select example"
        >
          <option value="care_giver">Care Giver</option>
          <option value="doctor">Doctor</option>
          <option value="foreign_medical">Foreign Medical</option>
          <option value="home_sample">Home Sample</option>
          <option value="nurse">Nurse</option>
          <option value="patient_guide">Patient Guide</option>
          <option value="therapist">Therapist</option>
          <option value="global_package_subscription">
            Global Package Subscription
          </option>
        </select>
        <button onClick={getData} className="btn btn-secondary ms-4">
          Search
        </button>
      </div>
      <div className="col-md-12 mt-5">
        <table class="table table-striped table-bordered">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">User Name</th>
              <th scope="col">Patient Name</th>
              <th scope="col">Service Name</th>
              <th scope="col">Price</th>
              <th scope="col">Discount</th>
              <th scope="col">Stock Share</th>
              <th scope="col">Payment Status</th>
              <th scope="col">Payment Method</th>
              <th scope="col">Transaction ID</th>
              <th scope="col">Appointment Status</th>
              <th scope="col">Date</th>
            </tr>
          </thead>
          <tbody>
            {reports.map((row, index) => (
              <tr>
                <td>{index + 1}</td>
                <td>{row.patient_name}</td>
                <td>{row.user.name}</td>
                <td>{getServiceName(row)}</td>
                <td>{row?.price || "N/A"}</td>
                <td>{row?.discount || "N/A"}</td>
                <td>{row?.share || "N/A"}</td>
                <td>{row.order?.status || "N/A"}</td>
                <td>Unknown</td>
                <td>{row.order?.transaction_id || "N/A"}</td>
                <td>{row.status || "N/A"}</td>
                <td>{new Date(row.created_at).toDateString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default ReportTable;
