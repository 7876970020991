import axios from "axios";

const baseURL = "https://parentscarexyz.com/api";

export const allForeignMedicalLocations = async (pageNumber) =>
    await axios.get(`/admin/foreign_medical_locations`);

export const addForeignMedicalLocation = async (data) =>
    await axios.post(`/admin/foreign_medical_locations`, data);

export const editForeignMedicalLocation = async (id, data) =>
    await axios.put(`/admin/foreign_medical_locations/${ id }`, data);

export const deleteForeignMedicalLocation = async (id, data) =>
    await axios.delete(`/admin/foreign_medical_locations/${ id }`);


export const allForeignMedicalRequests = async (pageNumber) =>
    await axios.get(`/admin/foreign_medical_appointments?page=${ pageNumber }`);

export const addForeignMedicalRequest = async (data) =>
    await axios.post(`/admin/foreign_medical_appointments`, data);

export const editForeignMedicalRequest = async (id, data) =>
    await axios.put(`/admin/foreign_medical_appointments/${ id }`, data);

export const deleteForeignMedicalRequest = async (id, data) =>
    await axios.delete(`/admin/foreign_medical_locations/${ id }`);