import { Button, useToast } from '@chakra-ui/react'
import React, { useEffect } from 'react'
import { deleteHomeSampleCategory, editHomeSampleCategory } from '../../state/api/home_sample'
import HomeSampleSubCategoryListTable from '../HomeSampleSubCategory/HomeSampleSubCategoryListTable'
import ModalComponent from '../ModalComponent'
import AddHomeSampleCategory from './AddHomeSampleCategory'
import EditHomeSampleCategory from './EditHomeSampleCategory'

function HomeSampleCategoryListTable({ setDep, HomeSampleCategories, dep }) {
    const toast = useToast();
    useEffect(() => {

    }, [dep]);
    return (
        <div>
            <div className="d-flex justify-content-between pb-3">
                <h1 className="fw-bold fs-3 pt-3 pb-3">
                    Home Sample Category List
                </h1>

                <ModalComponent
                    btnClassName={"add-people"}
                    btnIcon={"fas fa-plus-circle"}
                    btnText={"Add Category"}
                    title={"Add Category"}
                >
                    <AddHomeSampleCategory
                        setDep={setDep}
                    />
                </ModalComponent>
            </div>

            {/* list table */}
            <table className="table main-table">
                <thead>
                    <tr>

                        <th scope="col">
                            Name
                        </th>
                        <th scope="col">
                            Status
                        </th>
                        <th scope="col">
                            Action
                        </th>
                        <th scope="col">

                        </th>
                    </tr>
                </thead>
                <div className="pt-3">

                </div>
                <tbody >
                    {
                        HomeSampleCategories?.map((HomeSampleCategory, index) => {
                            return (
                                <tr >

                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{HomeSampleCategory.name}</h1>
                                    </td>
                                    <td className="activeInactiveCheckBox">
                                        <div className="form-check form-switch">
                                            {
                                                HomeSampleCategory.is_active === "1"
                                                    ? <input onChange={async () => {
                                                        await editHomeSampleCategory(HomeSampleCategory.id, { is_active: false });
                                                        setDep(Math.random());
                                                    }} defaultChecked className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                    : <input onChange={async () => {
                                                        await editHomeSampleCategory(HomeSampleCategory.id, { is_active: true });
                                                        setDep(Math.random());
                                                    }} className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <ModalComponent
                                            btnClassName={"add-people"}
                                            btnIcon={"far fa-eye"}
                                            btnText={"View"}
                                            title={"HEMATOLOGY"}
                                        >
                                            {/* HEMATOLOGY's sub category Component */}
                                            <HomeSampleSubCategoryListTable
                                                HomeSampleCategory={HomeSampleCategory}
                                            />
                                        </ModalComponent>
                                    </td>
                                    <td className="text-center">
                                        <ModalComponent
                                            btnClassName={"btn-editUserProfile"}
                                            btnIcon={"fas fa-edit"}
                                            btnText={"Edit"}
                                            title={"Edit Doctor"}
                                        >
                                            {/* Edit Doctor component */}
                                            <EditHomeSampleCategory
                                                HomeSampleCategory={HomeSampleCategory}
                                                setDep={setDep}
                                            />
                                        </ModalComponent>
                                        <br />
                                        <Button
                                            onClick={async () => {
                                                await deleteHomeSampleCategory(HomeSampleCategory.id);
                                                setDep(Math.random());
                                                toast({
                                                    title: "Deleted",
                                                    description: "Home Sample Category Deleted Successfully",
                                                    status: "success",
                                                    duration: 9000,
                                                    isClosable: true,
                                                    position: "bottom-right",
                                                    isPauseOnHover: true,
                                                })
                                            }}
                                            mt="1"
                                            padding="0px 35px"
                                            fontSize="12"
                                            height="25px"
                                            borderRadius="30px"
                                            color="#000">
                                            <i class="far fa-trash-alt me-2"></i>
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div >
    )
}

export default HomeSampleCategoryListTable
