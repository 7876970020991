import axios from "axios";

const baseURL = "https://parentscarexyz.com/api";

export const allHomeSampleCategory = async (pageNumber) =>
    await axios.get(`/admin/home_sample_categories?page=${ pageNumber }`);

export const addHomeSampleCategory = async (body) =>
    await axios.post(`/admin/home_sample_categories`, body);

export const editHomeSampleCategory = async (id, body) =>
    await axios.put(`/admin/home_sample_categories/${ id }`, body);

export const deleteHomeSampleCategory = async (id) =>
    await axios.delete(`/admin/home_sample_categories/${ id }`);


export const HomeSampleCategoryByID = async (id) =>
    await axios.get(`/admin/home_sample_categories/${ id }`);

export const addHomeSampleSubCategory = async (body) =>
    await axios.post(`/admin/home_sample_subcategories`, body);


export const deleteHomeSampleSubCategory = async (id) =>
    await axios.delete(`/admin/home_sample_subcategories/${ id }`);

export const editHomeSampleSubCategory = async (id, body) =>
    await axios.put(`/admin/home_sample_subcategories/${ id }`, body);
