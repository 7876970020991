import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider } from '@chakra-ui/react';
import { store } from "./state/store";
import { Provider } from "react-redux";
import axios from 'axios';
axios.defaults.baseURL = 'https://parentscarexyz.com/api';
axios.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.getItem("token") }`;
axios.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  if (Number(error.response.status) === 401) {
    localStorage.clear();
    window.location.href = '/';
  }
  return Promise.reject(error);
});
let cancelToken;
axios.interceptors.request.use(function (config) {
  if (typeof cancelToken != "undefined") {
    cancelToken.cancel("Canceling the previous call!");
  }

  cancelToken = axios.CancelToken.source();
  config.cancelToken = cancelToken.token;
  return config;
}, function (error) {
  return Promise.reject(error);
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ChakraProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ChakraProvider>
    </Provider>

  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
