import React from 'react'
import AppointmentHistory from '../AppointmentHistory'
import AppointmentCard from '../Cards/AppointmentCard'
import ModalComponent from '../ModalComponent'
import PeopleBasicInfo from '../PeopleBasicInfo'

function DoctorDetails({ doctor }) {
    console.log(doctor.ongoing_appointments)
    return (
        <div className="container pb-5">
            <div className="row">
                <div className="col-md-9">
                    <PeopleBasicInfo
                        data={doctor}
                    />
                    <h6 className="mt-3 fs-5 fw-bold">Bio</h6>
                    <p>
                        {doctor?.bio}
                    </p>
                    <ModalComponent
                        btnClassName={"add-people"}
                        // btnIcon={"fas fa-plus-circle"}
                        btnText={"See Appointment History"}
                        title={"Appointment History"}
                    >
                        <AppointmentHistory
                            data={doctor}
                            type={"doctors"}
                        />
                    </ModalComponent>
                </div>
                <div className="col-md-3  appointmentWrapper">
                    <h3 className="fw-bold fs-5">
                        Customer Appointment
                    </h3>
                    {
                        doctor?.ongoing_appointments?.map((appointment, index) => {
                            return (
                                <AppointmentCard data={appointment} />
                            )
                        })
                    }
                </div>
            </div>

        </div>
    )
}

export default DoctorDetails
