import React, { useEffect, useState } from 'react'
import ForeignMedicalAssListTable from '../../components/ForeignMedicalAss/ForeignMedicalAssListTable'
import Pagination from '../../components/Pagination';
import { allForeignMedicalRequests } from '../../state/api/foreign_medical';


function ForeignMedicalAssList() {
    const [foreignMedicalRequests, setforeignMedicalRequest] = useState([]);
    const [dep, setDep] = useState(null);

    // code of pagination start
    const [pageNumber, setPageNumber] = useState(1);
    const [numberOfPages, setNumberOfPages] = useState(0);
    // code of pagination end

    const handleGetAndSetforeignMedicalRequest = async (pageNumber) => {
        try {
            const { data } = await allForeignMedicalRequests(pageNumber);
            setforeignMedicalRequest(data);
            setNumberOfPages(Math.ceil(data.total / data.per_page));
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        handleGetAndSetforeignMedicalRequest(pageNumber);
    }, [dep, pageNumber]);
    return (
        <div className="container pt-5">
            <h1 className="fw-bold fs-1">Foreign Medical Assistance</h1>
            <ForeignMedicalAssListTable
                foreignMedicalRequests={foreignMedicalRequests.data}
                setDep={setDep}
            />
            <div className="text-center pb-3">
                {numberOfPages > 1 && (
                    <Pagination
                        setPageNumber={setPageNumber}
                        numberOfPages={numberOfPages}
                        pageNumber={pageNumber}
                    />
                )}
            </div>
        </div>
    )
}

export default ForeignMedicalAssList
