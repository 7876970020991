import { useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { editNurse } from '../../state/api/nurse';
import { uploadImage } from '../../state/api/upImage';
import { deleteEmptyFiled } from '../../utils';

function EditNurse({ setDep, nurse }) {
    const days = [
        {
            id: 1,
            name: "Saturday",
            available: false
        },
        {
            id: 2,
            name: "Sunday",
            available: false
        },
        {
            id: 3,
            name: "Monday",
            available: false
        },
        {
            id: 4,
            name: "Tuesday",
            available: false
        },
        {
            id: 5,
            name: "Wednesday",
            available: false
        },
        {
            id: 6,
            name: "Thursday",
            available: false
        },
        {
            id: 7,
            name: "Friday",
            available: false
        }
    ];

    // this state using at label of image input
    const [selectedImg, setSelectedImg] = useState(nurse.photo);
    const toast = useToast();

    const [imgURL, setImgURL] = useState("");
    const [errorMsg, setErrorMsg] = useState(null);

    const handleImageUpload = async (file) => {
        console.log(file);
        try {
            const imgData = new FormData();
            imgData.append("photo", file);

            const { data } = await uploadImage(imgData);
            console.log(data);
            setImgURL(data.url);
            toast({
                title: 'Image Upload Successfully',
                // description: "We've created your account for you.",
                status: 'success',
                duration: 9000,
                isClosable: false,
                position: 'bottom-right',
            });
        } catch (error) {

        }
    }
    const [availableDays, setAvailableDays] = useState([]);
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const onSubmit = async (formData) => {
        if (imgURL) {
            formData.photo = imgURL;
        }
        if (availableDays) {
            formData.availabilities = availableDays.toString();
        }
        try {
            const { data } = await editNurse(nurse.id, deleteEmptyFiled(formData));
            console.log(data);
            setDep(data);
            setErrorMsg(data?.message);
            reset();
            toast({
                title: 'Nurse edited successfully',
                // description: "We've created your account for you.",
                status: 'success',
                duration: 9000,
                isClosable: false,
                position: 'bottom-right',
            });
            window.location.reload(false);
        } catch (error) {

        }
    };

    useEffect(() => {
        return () => {

        }
    }, []);

    return (
        <div className="container">
            <form onSubmit={handleSubmit(onSubmit)} action="">
                <div className="row">
                    <div className="col-md-8">
                        <div className="d-flex">
                            {/* first column */}
                            <div className="w-50 ">
                                <div class="mb-3">
                                    <label for="name" class="form-label text-light">Name</label>
                                    <input
                                        defaultValue={nurse.name}
                                        {...register("name", { required: false })}
                                        type="name" class="form-control input-primary" id="name" aria-describedby="emailHelp" />
                                </div>
                                <div class="mb-3">
                                    <label for="dateOfBirth" class="form-label text-light">Date of Birth</label>
                                    <input
                                        defaultValue={new Date(nurse.dob).toISOString().substring(0, 10)}
                                        {...register("dob", { required: false })}
                                        type="date" class="form-control  input-primary" id="dateOfBirth" />
                                </div>
                                <div class="mb-3">
                                    <label for="Email" class="form-label text-light">Email</label>
                                    <input
                                        defaultValue={nurse.email}
                                        {...register("email", { required: false })}
                                        type="email" class="form-control  input-primary" id="Email" />
                                </div>
                                <div class="mb-3">
                                    <label for="Phone" class="form-label text-light">Phone</label>
                                    <input
                                        defaultValue={nurse.phone}
                                        {...register("phone", { required: false })}
                                        type="phoneNumber" class="form-control input-primary" id="Phone" />
                                </div>
                            </div>
                            {/* 2d column */}
                            <div className="w-50 ps-3">
                                <div class="mb-3">
                                    <label for="designation" class="form-label text-light">Designation</label>
                                    <input
                                        defaultValue={nurse.designation}
                                        {...register("designation", { required: false })}
                                        type="name" class="form-control input-primary" id="designation" aria-describedby="emailHelp" />
                                </div>
                                <div class="mb-3">
                                    <label for="Gender" class="form-label text-light">Gender</label>
                                    <select
                                        {...register("gender", { required: false })}
                                        class="form-select input-primary" id="Gender" aria-label="Default select example">
                                        <option disabled selected>{nurse.gender}</option>
                                        <option value={"Female"}>Female</option>
                                        <option value={"Male"}>Male</option>
                                        <option value={"Other"}>Other</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="category" class="form-label text-light">Is special?</label>
                                    <select
                                        {...register("is_special", { required: false })}
                                        class="form-select input-primary" id="category" aria-label="Default select example">
                                        <option disabled selected>{nurse.is_special ? "Special" : "Regular"}</option>
                                        <option value={0}>Regular</option>
                                        <option value={1}>Special</option>
                                    </select>
                                </div>
                                <div class="mb-3">
                                    <label for="location" class="form-label text-light">Location</label>
                                    <input
                                        defaultValue={nurse.location}
                                        {...register("location", { required: false })}
                                        type="address" class="form-control input-primary" id="location" />
                                </div>

                            </div>
                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label text-light">Bio</label>
                            <textarea
                                defaultValue={nurse.bio}
                                {...register("bio", { required: false })}
                                class="form-control input-primary" id="exampleFormControlTextarea1" rows="3"></textarea>
                        </div>
                    </div>

                    <div className="col-md-4 ">
                        <div className="mb-3 text-center">
                            <label for="image" class="form-label ">
                                {selectedImg && (
                                    <img
                                        alt=""
                                        src={selectedImg}
                                        className="img-fluid rounded-circle"
                                        style={{ height: "250px", width: "250px", margin: "0 auto" }}
                                    />
                                )}
                            </label>
                            <input
                                onChange={(e) => {
                                    setSelectedImg(URL.createObjectURL(e.target.files[0]));
                                    handleImageUpload(e.target.files[0]);
                                }}
                                type="file"
                                accept="image/gif, image/jpeg, image/png"
                                class={selectedImg ? "form-control input-primary" : "form-control input-primary"}
                                id="image" />
                        </div>
                        <div class="mb-3 ">
                            <label for="address" class="form-label text-light">Price</label>
                            <div className="special-input">
                                <input
                                    defaultValue={nurse.price}
                                    {...register("price", { required: false })}
                                    type="number" class="form-control  input-primary" id="address" />
                                <span>BDT</span>
                            </div>
                        </div>
                        <div class="mb-3 ">
                            <label for="address" class="form-label text-light">Discount</label>
                            <div className="special-input">
                                <input
                                    defaultValue={nurse.discount}
                                    {...register("discount", { required: false })}
                                    type="number" class="form-control  input-primary" id="address" />
                                <span>%</span>
                            </div>
                        </div>
                        <div class="mb-3 ">
                            <label for="address" class="form-label text-light">Profit share</label>
                            <div className="special-input">
                                <input
                                    defaultValue={nurse.share}
                                    {...register("share", { required: false })}
                                    type="number" class="form-control  input-primary" id="address" />
                                <span>%</span>
                            </div>
                        </div>
                        <div className="dayCardsWrapper">
                            {
                                days?.map((day, index) => {
                                    return (
                                        <div
                                            // onClick={() => (daysF)}
                                            onClick={() => setAvailableDays([...availableDays, day.name])}
                                            className={availableDays.includes(day.name) ? "dayCard availableDay" : "dayCard"}>{day.name}</div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>

                <div className="pt-3 pb-3 text-center">
                    <div className="text-center fw-bold text-danger pb-2">
                        {
                            errorMsg && <p>{errorMsg}</p>
                        }
                    </div>
                    <button className="btn btn-secondary">
                        Submit
                    </button>
                </div>
            </form>
        </div >
    )
}

export default EditNurse
