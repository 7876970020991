import axios from 'axios';
import React, { useEffect, useState } from 'react';

function SearchBer({ setData, type, pageNumber, setNumberOfPages }) {
    const [query, setQuery] = useState("");
    const handleSearch = async () => {
        try {
            const { data } = await axios.get(`/admin/${ type }?name=${ query }&page=${ pageNumber }`);
            setData(data);
            setNumberOfPages(Math.ceil(data.total / data.per_page));
        } catch (error) {
        }
    };

    useEffect(() => {
        handleSearch();
    }, [query]);
    return (
        <div class="search">
            <input onChange={(e) => setQuery(e.target.value)} type="text" class="searchTerm" placeholder="What are you looking for?" />
            <button type="submit" class="searchButton">
                <i class="fa fa-search"></i>
            </button>
        </div>
    );
}

export default SearchBer;
