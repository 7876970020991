import React, { useEffect, useState } from 'react';
import dateFormat from 'dateformat';
import { requestHistory } from '../state/api/requests';
import { getServiceName, getServicePath } from '../utils';

function AppointmentHistory({ data, type }) {
    const [appointments, setAppointments] = useState([]);

    const handleGetAppointments = async (type, id) => {
        try {
            const { data } = await requestHistory(type, id);
            setAppointments(data?.appointments);
            console.log(data);
        } catch (error) {

        }
    }
    useEffect(() => {
        handleGetAppointments(type, data.id);
    }, [data]);
    return (
        <div className="col-md-12 mt-3">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Service Name</th>
                        <th scope="col">Patient Name</th>
                        <th scope="col">Price</th>
                        <th scope="col">Discount</th>
                        <th scope="col">Profit Share</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        appointments?.map((appointment, index) => {
                            console.log(appointment)
                            return (
                                <tr>
                                    <td scope="row">{index + 1}</td>
                                    <td>
                                        {getServiceName(appointment)} Appointment
                                    </td>
                                    <td>{appointment?.patient_name}</td>
                                    <td>{appointment?.price}</td>
                                    <td>{appointment?.discount}</td>
                                    <td>{appointment?.share}</td>
                                    <td>{appointment?.status}</td>
                                    <td>{dateFormat(appointment?.created_at)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    );
}

export default AppointmentHistory;
