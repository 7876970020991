import axios from "axios";

export const allTherapists = async (pageNumber) =>
    await axios.get(`/admin/therapists?page=${ pageNumber }`);

export const addTherapists = async (data) =>
    await axios.post(`/admin/therapists`, data);

export const editTherapists = async (id, data) =>
    await axios.put(`/admin/therapists/${ id }`, data);

export const deleteTherapists = async (id) =>
    await axios.delete(`/admin/therapists/${ id }`);

export const allTherapistsLocation = async () =>
    await axios.get(`/admin/therapist_locations`);

export const addTherapistsLocation = async (body) =>
    await axios.post(`/admin/therapist_locations`, body);

export const editTherapistsLocation = async (id, body) =>
    await axios.put(`/admin/therapist_locations/${ id }`, body);

export const deleteTherapistsLocation = async (id) =>
    await axios.delete(`/admin/therapist_locations/${ id }`);