import { useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import Slugify from 'slugify';
import { addHomeSampleSubCategory } from '../../state/api/home_sample';

function AddHomeSampleSubCategory({ setDep, HomeSampleCategory }) {
    const [errorMsg, setErrorMsg] = useState(null);
    const toast = useToast();

    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const onSubmit = async (body) => {
        try {
            const data = await addHomeSampleSubCategory({ ...body, slug: Slugify(body.name), home_sample_category_id: HomeSampleCategory.id });
            setDep(Math.random());
            reset();
            toast({
                title: 'Home Sample Sub Category added successfully',
                // description: "We've created your account for you.",
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'bottom-right',
            });
        } catch (error) {
            setErrorMsg("Something went wrong! Try again later!");
        }
    };
    return (
        <div className="container">
            <form onSubmit={handleSubmit(onSubmit)} action="">
                <div className="row">
                    <div className="col-md-8">
                        <div className="d-flex">
                            {/* first column */}
                            <div className="w-50">
                                <div className=" ">
                                    <div class="mb-3">
                                        <label for="name" class="form-label text-light">Name</label>
                                        <input
                                            {...register("name", { required: true })}
                                            type="name" class="form-control input-primary" id="name" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className="">
                                    <div class="mb-3">
                                        <label for="Discount" class="form-label text-light">Discount</label>
                                        <input
                                            {...register("discount", { required: true })}
                                            type="number" class="form-control input-primary" id="Discount" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                            </div>
                            <div className="w-50">
                                <div className="ms-3">
                                    <div class="mb-3">
                                        <label for="price" class="form-label text-light">Price</label>
                                        <input
                                            {...register("price", { required: true })}
                                            type="number" class="form-control input-primary" id="price" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                                <div className=" ms-3">
                                    <div class="mb-3">
                                        <label for="Share" class="form-label text-light">Share</label>
                                        <input
                                            {...register("share", { required: true })}
                                            type="number" class="form-control input-primary" id="Share" aria-describedby="emailHelp" />
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

                <div className="pt-3 pb-3 text-center">
                    <div className="text-center fw-bold text-danger pb-2">
                        {
                            errorMsg && <p>{errorMsg}</p>
                        }
                    </div>
                    <button className="btn btn-secondary">
                        Submit
                    </button>
                </div>
            </form>
        </div >
    )
}

export default AddHomeSampleSubCategory
