import React from 'react'
import dateFormat from 'dateformat'

function UserPrevAppointmentTable({ user }) {
    return (
        <div className="col-md-12 mt-3">
            <table class="table table-striped table-bordered">
                <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Service Name</th>
                        <th scope="col">Price</th>
                        <th scope="col">Discount</th>
                        <th scope="col">Profit Share</th>
                        <th scope="col">Payment</th>
                        <th scope="col">Transaction ID</th>
                        <th scope="col">Status</th>
                        <th scope="col">Date</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        user?.appointments?.map((appointment, index) => {
                            console.log(appointment)
                            return (
                                <tr>
                                    <td scope="row">{index + 1}</td>
                                    <td>
                                        {appointment?.doctor && "Doctor Appointment"}
                                        {appointment?.nurse && "Nurse Appointment"}
                                        {appointment?.home_sample && "Home Sample Appointment"}
                                        {appointment?.therapist && "Therapist Appointment"}
                                        {appointment?.foreign_medical && "Foreign Medical Appointment"}
                                        {appointment?.care_giver_service_id && "Care Giver Appointment"}
                                        {appointment?.patient_guide && "Patient Guide Appointment"}
                                    </td>
                                    <td>{appointment?.price}</td>
                                    <td>{appointment?.discount}</td>
                                    <td>{appointment?.share}</td>
                                    <td>{appointment?.order?.status}</td>
                                    <td>{appointment?.order?.transaction_id}</td>
                                    <td>{appointment?.status}</td>
                                    <td>{dateFormat(appointment?.created_at)}</td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}

export default UserPrevAppointmentTable
