import { useToast } from '@chakra-ui/react';
import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { editNursePackage } from '../../state/api/nurse';
import { deleteEmptyFiled } from '../../utils';

function EditNursePackage({ nursePackage, setDep }) {
    const [errorMsg, setErrorMsg] = useState(null);
    const toast = useToast();
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const onSubmit = async (formData) => {
        try {
            console.log(formData);
            const { data } = await editNursePackage(nursePackage.id, deleteEmptyFiled(formData));
            console.log(data);
            setDep(data);
            reset();
            toast({
                title: 'Nurse Package Edited successfully',
                // description: "We've created your account for you.",
                status: 'success',
                duration: 9000,
                isClosable: false,
                position: 'bottom-right',
            });
            window.location.reload(false);
        } catch (error) {
            setErrorMsg(JSON.stringify(error?.response?.data?.errors));
        }
    };

    return (
        <div className="container">
            <form onSubmit={handleSubmit(onSubmit)} action="">
                <div className="row">
                    <div className="col-md-4">

                        <div class="mb-3">
                            <label for="name" class="form-label text-light">Package Name</label>
                            <input
                                defaultValue={nursePackage.name}
                                {...register("name", { required: false })}
                                type="name" class="form-control input-primary" id="name" aria-describedby="emailHelp" />
                        </div>
                        <div class="mb-3">
                            <label for="Duration" class="form-label text-light">Duration</label>
                            <select
                                {...register("duration", { required: false })}
                                class="form-select input-primary" id="Duration" aria-label="Default select example">
                                <option disabled>{nursePackage.duration}</option>
                                <option value="7">7 Days</option>
                                <option value="15">15 Days</option>
                                <option value="30">30 Days</option>
                            </select>
                        </div>

                    </div>
                    <div className="col-md-4">

                        <div class="mb-3">
                            <label for="Gender" class="form-label text-light">Category</label>
                            <select
                                {...register("is_special", { required: false })}
                                class="form-select input-primary" id="Gender" aria-label="Default select example">
                                <option disabled>{nursePackage.is_special ? "Special" : "Regular"}</option>
                                <option value="1">Special</option>
                                <option value="0">Regular</option>
                            </select>
                        </div>
                        <div class="mb-3 ">
                            <label for="Price" class="form-label text-light">Price</label>
                            <div className="special-input">
                                <input
                                    defaultValue={nursePackage.price}
                                    {...register("price", { required: false })}
                                    type="number" class="form-control  input-primary" id="Price" />
                                <span>BDT</span>
                            </div>
                        </div>
                        <div class="mb-3 ">
                            <label for="address" class="form-label text-light">Profit share</label>
                            <div className="special-input">
                                <input
                                    defaultValue={nursePackage.share}
                                    {...register("share", { required: false })}
                                    type="number" class="form-control  input-primary" id="address" />
                                <span>%</span>
                            </div>
                        </div>
                        <div class="mb-3 ">
                            <label for="Discount" class="form-label text-light">Discount</label>
                            <div className="special-input">
                                <input
                                    defaultValue={nursePackage.discount}
                                    {...register("discount", { required: false })}
                                    type="number" class="form-control  input-primary" id="Discount" />
                                <span>%</span>
                            </div>
                        </div>

                    </div>
                </div>
                <div className="pt-3 pb-3 text-center">
                    <div className="text-center fw-bold text-danger pb-2">
                        {
                            errorMsg && <p>{errorMsg}</p>
                        }
                    </div>
                    <button type='submit' className="btn btn-secondary">
                        Submit
                    </button>
                </div>
            </form>
        </div >
    )
}

export default EditNursePackage
