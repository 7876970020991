import { useToast } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { editBlogs } from '../../state/api/blog';
import { uploadImage } from '../../state/api/upImage';
import { deleteEmptyFiled } from '../../utils';

function EditBlog({ setDep, blog }) {
    console.log(blog)
    const toast = useToast();
    const [selectedImg, setSelectedImg] = useState(null);
    const [imgURL, setImgURL] = useState("");
    const handleImageUpload = async (file) => {
        console.log(file);
        try {
            const imgData = new FormData();
            imgData.append("photo", file);

            const { data } = await uploadImage(imgData);
            console.log(data);
            setImgURL(data.url);
            toast({
                title: 'Image uploaded successfully',
                // description: "We've created your account for you.",
                status: 'success',
                duration: 9000,
                position: 'bottom-right',
            });
        } catch (error) {

        }
    };
    const [errorMsg, setErrorMsg] = useState(null);
    const { register, handleSubmit, watch, reset, formState: { errors } } = useForm();
    const onSubmit = async (formData) => {
        if (imgURL) {
            formData.photo = imgURL;
        }
        try {
            const { data } = await editBlogs(blog.id, deleteEmptyFiled(formData));
            console.log(data);
            setDep(data);
            setErrorMsg(data?.message);
            reset();
            toast({
                title: 'Blog updated successfully!',
                // description: "We've created your account for you.",
                status: 'success',
                duration: 9000,
                isClosable: false,
                position: 'bottom-right',
            });
            window.location.reload(false);
        } catch (error) {
            setErrorMsg(JSON.stringify(error?.response?.data?.errors));
        }
    };

    useEffect(() => {
        return () => {

        }
    }, []);
    return (
        <div className="container">
            <form onSubmit={handleSubmit(onSubmit)} action="">
                <div className="row">
                    <div className="col-md-4">
                        <div class="mb-3">
                            <label for="name" class="form-label text-light">Title</label>
                            <input
                                defaultValue={blog?.title}
                                {...register("title", { required: false })}
                                type="text" class="form-control input-primary" id="name" aria-describedby="emailHelp" />
                        </div>

                    </div>
                    {/* 2nd column */}
                    <div className="col-md-4">
                        <div class="mb-3">
                            <label for="name" class="form-label text-light">Sub-Title</label>
                            <input
                                defaultValue={blog?.subtitle}
                                {...register("subtitle", { required: false })}
                                type="text" class="form-control input-primary" id="name" aria-describedby="emailHelp" />
                        </div>

                    </div>
                    <div className="col-md-4">
                        <div className="mb-3 text-center">
                            <label for="image" class="form-label ">
                                {selectedImg && (
                                    <img
                                        alt=""
                                        src={selectedImg}
                                        className="img-fluid rounded-circle"
                                        style={{ height: "250px", width: "250px", margin: "0 auto" }}
                                    />
                                )}
                            </label>
                            <input
                                onChange={(e) => {
                                    setSelectedImg(URL.createObjectURL(e.target.files[0]));
                                    handleImageUpload(e.target.files[0]);
                                }}
                                type="file"
                                accept="image/gif, image/jpeg, image/png"
                                class={selectedImg ? "form-control input-primary" : "form-control input-primary"}
                                id="image" />
                        </div>
                    </div>

                    <div class="mb-3 col-md-8">
                        <label for="description" class="form-label text-light">Description</label>
                        <textarea
                            defaultValue={blog?.description}
                            {...register("description", { required: false })}
                            class="form-control input-primary" id="description" rows="3"></textarea>
                    </div>
                </div>
                <div className="pt-3 pb-3 text-center">
                    <div className="text-center fw-bold text-danger pb-2">
                        {
                            errorMsg && <p>{errorMsg}</p>
                        }
                    </div>
                    <button className="btn btn-secondary">
                        Submit
                    </button>
                </div>
            </form>
        </div >
    )
}

export default EditBlog;
