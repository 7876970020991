import { Button, useToast } from '@chakra-ui/react'
import React from 'react'
import { deleteTherapists } from '../../state/api/therapists'
import ModalComponent from '../ModalComponent'
import SearchBer from '../SearchBer'
import AddTherapist from './AddTherapist'
import EditTherapist from './EditTherapist'
import TherapistDetails from './TherapistDetails'

function TherapistListTable({ therapists, setDep, setData, pageNumber, setNumberOfPages }) {
    const toast = useToast();
    return (
        <div>
            <div className="d-flex justify-content-between pb-3">
                <h1 className="fw-bold fs-3 pt-3 pb-3">
                    Therapist List
                </h1>

                {/* search bar */}
                <SearchBer
                    setData={setData}
                    type={"therapists"}
                    pageNumber={pageNumber}
                    setNumberOfPages={setNumberOfPages}
                />

                <ModalComponent
                    btnClassName={"add-people"}
                    btnIcon={"fas fa-plus-circle"}
                    btnText={"Add Therapist"}
                    title={"Add Therapist"}
                >
                    <AddTherapist
                        setDep={setDep} />
                </ModalComponent>
            </div>

            {/* list table */}
            <table className="table main-table">
                <thead>
                    <tr>

                        <th scope="col">
                            Name and Details
                        </th>
                        <th scope="col">
                            Location
                        </th>
                        <th scope="col">
                            Contact
                        </th>
                        <th scope="col">
                            Status
                        </th>
                        <th scope="col">
                            Action
                        </th>
                        <th scope="col">

                        </th>
                    </tr>
                </thead>
                <div className="pt-3">

                </div>
                <tbody >
                    {
                        therapists?.map((therapist, index) => {
                            return (
                                <tr >

                                    <td className="userDetailsInTable">
                                        <div className="d-flex align-items-center">
                                            <img src={therapist.photo}
                                                className="img-fluid rounded-circle userImage"
                                                alt={therapist.name} />
                                            <div className="ms-3">
                                                <h1 className="fw-600 fs-4">{therapist.name}</h1>
                                                <p className="text-light">{therapist.email}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{therapist.location.address}</h1>
                                    </td>
                                    <td className="">
                                        <h1 className="fw-normal fs-5 pt-2">{therapist.phone}</h1>
                                    </td>
                                    <td className="activeInactiveCheckBox">
                                        <div className="form-check form-switch">
                                            <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                        </div>
                                    </td>
                                    <td>
                                        <ModalComponent
                                            btnClassName={"add-people"}
                                            btnIcon={"far fa-eye"}
                                            btnText={"View"}
                                            title={"Therapist Details"}
                                        >
                                            {/* Therapist Details Component */}
                                            <TherapistDetails
                                                therapist={therapist}
                                            />
                                        </ModalComponent>
                                    </td>
                                    <td className="text-center">
                                        <ModalComponent
                                            btnClassName={"btn-editUserProfile"}
                                            btnIcon={"fas fa-edit"}
                                            btnText={"Edit"}
                                            title={"Edit Therapist"}
                                        >
                                            {/* Edit Therapist component */}
                                            <EditTherapist
                                                therapist={therapist}
                                                setDep={setDep}
                                            />
                                        </ModalComponent>
                                        <br />
                                        <Button
                                            onClick={async () => {
                                                await deleteTherapists(therapist.id);
                                                setDep(Math.random());
                                                toast({
                                                    title: 'Therapist Deleted',
                                                    description: 'Therapist has been deleted successfully',
                                                    status: 'success',
                                                    duration: 9000,
                                                    isClosable: true,
                                                    position: 'bottom-right',
                                                });
                                            }}
                                            mt="1"
                                            padding="0px 35px"
                                            fontSize="12"
                                            height="25px"
                                            borderRadius="30px"
                                            color="#000">
                                            <i class="far fa-trash-alt me-2"></i>
                                            Delete
                                        </Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
        </div >
    )
}

export default TherapistListTable
