import React from 'react'
import '../../styles/AppointmentCard.css';
import { getServiceName } from '../../utils';

function AppointmentCard({ data }) {
    console.log(data, "appointmentCard")
    return (
        <div className="appointmentCard p-3">
            <p>{getServiceName(data)} Appointment</p>
            <h4 className="fw-bold fs-5">{data.patient_name}</h4>
            <div>
                <ul className="pt-3 ps-3">
                    <li>{data.status}</li>
                    <li>{data?.price}</li>
                </ul>
            </div>
            {data.sickness_details &&
                <p className="pt-3">
                    {data?.sickness_details}
                </p>
            }
            <h5 className='bg-light p-2 rounded-pill text-dark date mt-3'>{new Date(data?.created_at).toDateString()}</h5>
        </div>
    )
}

export default AppointmentCard
