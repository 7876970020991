import React from 'react';

function BlogDetails({ blog }) {
    console.log(blog)
    return (
        <div>
            <img src={blog.photo} className='img-fluid' alt="" />
            <h3 className='pt-2 fw-bold fs-4'>{blog?.title}</h3>
            <h6 className='pt-2 fw-blod fs-5'>{blog?.subtitle}</h6>
            <p className='pt-2'>{blog?.description}</p>
        </div>
    );
}

export default BlogDetails;
