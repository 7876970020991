import { configureStore } from '@reduxjs/toolkit'
import counterReducer from './reducer/counter';
import loginReducer from './reducer/login';
import UserReducer from './reducer/user';

export const store = configureStore({
    reducer: {
        counter: counterReducer,
        login: loginReducer,
        user: UserReducer,
    },
})